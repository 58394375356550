import { useEffect, useRef, useState } from 'react'

import { Font } from '@samuelmeuli/font-manager'
import * as cheerio from 'cheerio'
import type {
  FindWebsitePanelQuery,
  FindWebsitePanelQueryVariables,
  UpdateWebsiteInput,
  Website,
} from 'types/graphql'

import { Metadata } from '@redwoodjs/web'
import { type TypedDocumentNode, useQuery, useMutation } from '@redwoodjs/web'

import { useAuth } from 'src/auth'
import ChatPanel, { ChatPanelHandler } from 'src/components/Chat/ChatPanel'
import DomainSearchPanel from 'src/components/Chat/DomainSearchPanel'
import FavoriteImagePickerPanel, {
  FavoriteImagePickerPanelHandler,
} from 'src/components/Chat/FavoriteImagePickerPanel'
import GalleryPanel from 'src/components/Chat/GalleryPanel'
import Navbar from 'src/components/Chat/Navbar'
import PageToolbar from 'src/components/Chat/PageToolbar'
import SettingsPanel from 'src/components/Chat/SettingsPanel'
import TemplatePickerPanel from 'src/components/Chat/TemplatePickerPanel'
import WebsiteLoadingPanel from 'src/components/Chat/WebsiteLoadingPanel'
import WebsiteLoadingProgressBar from 'src/components/Chat/WebsiteLoadingProgressBar'
import WebsitePanel, {
  WebsitePanelHandler,
} from 'src/components/Chat/WebsitePanel'
import {
  CodeSection,
  FrameElementEvent,
  PageWithCodeSections,
} from 'src/components/PageFrame/PageFrame'
import { buildFontUrl, defaultFonts } from 'src/lib/fonts'
import {
  Media,
  replaceClickedElementWithMedia,
  getUpdatedSeoImageTags,
} from 'src/lib/media'

type WebsiteWidth = 'mobile' | 'tablet' | 'laptop' | 'desktop'

const FIND_WEBSITE: TypedDocumentNode<
  FindWebsitePanelQuery,
  FindWebsitePanelQueryVariables
> = gql`
  query FindWebsitePanelQuery($id: String!) {
    website: website(id: $id) {
      id
      name
      url
      businessName
      businessAbout
      createdAt
      startedGeneratingAt
      finishedGeneratingAt
      onboardingQuestions
      onboardingAnswers
      hasRunOnboardingFunctions
      additionalInformationFields
      notifyWhenFinishedEmail
      nextStepStateIndex
      hasCompletedOnboarding
      hasCompletedBlogOnboarding
      hasAddedBlogLinkToHomepage
      uniqueUserToken
      headerCodeSection
      footerCodeSection
      colorPalette
      googleAnalyticsId
      contactFormEmail
      agencyShortCode
      scriptsInHtml
      contentInHead
      fonts
      customDomain
      verifiedDomain
      stripePlan
      cloudflareZoneId
      cloudflareZoneActivatedOn
      cloudflareOriginalDNSHost
      cloudflareOriginalNameservers
      cloudflareOriginalRegistrar
      cloudflareNameservers
      nameservers
      waitingForSSLCertificate
      user {
        id
        agencyOwnerName
      }
      Pages {
        id
        name
        path
        isHomepage
        html
        codeSections
        imagesKeyword
        primaryColor
        startedGeneratingAt
        finishedGeneratingAt
        hasAppliedPrimaryColorOnFirstLoad
        seoDescription
        seoTitle
        seoMetaTags
      }
    }
  }
`

const SAVE_WEBSITE = gql`
  mutation SaveChatWebsiteMutation($id: String!, $input: UpdateWebsiteInput!) {
    updateWebsite(id: $id, input: $input) {
      __typename
      id
      googleAnalyticsId
      headerCodeSection
      footerCodeSection
      fonts
    }
  }
`

const SAVE_ACTIVE_PAGE = gql`
  mutation SaveActivePageMutation($id: String!, $input: UpdatePageInput!) {
    updatePage(id: $id, input: $input) {
      __typename
      id
      codeSections
      imagesKeyword
      primaryColor
      hasAppliedPrimaryColorOnFirstLoad
    }
  }
`

const START_BUILDING_WEBSITE = gql`
  mutation StartBuildingWebsiteMutation($id: String!, $templateId: String!) {
    startBuildingWebsite(id: $id, templateId: $templateId) {
      __typename
      id
      startedGeneratingAt
    }
  }
`

const START_BUILDING_PAGE = gql`
  mutation StartBuildingPageMutation($id: String!) {
    startBuildingPage(id: $id) {
      __typename
      id
      startedGeneratingAt
      finishedGeneratingAt
    }
  }
`

const PUBLISH_IMAGE_AND_GET_URL = gql`
  mutation PublishImageAndGetUrlMutation(
    $id: String!
    $input: PublishImageAndGetUrlInput!
  ) {
    publishImageAndGetUrl(id: $id, input: $input)
  }
`

const PUBLISH_WEBSITE = gql`
  mutation PublishWebsiteMutation(
    $id: String!
    $publishToFreeDomain: Boolean!
    $publishToProDomain: Boolean!
  ) {
    publishWebsite(
      id: $id
      publishToFreeDomain: $publishToFreeDomain
      publishToProDomain: $publishToProDomain
    ) {
      __typename
      id
      freePublishedAt
      proPublishedAt
    }
  }
`

const CLAIM_WEBSITE = gql`
  mutation ClaimWebsiteMutation($id: String!) {
    claimWebsite(id: $id) {
      __typename
      id
      user {
        id
        email
      }
    }
  }
`

const DELETE_PAGE = gql`
  mutation DeletePageMutation($id: String!) {
    deletePage(id: $id) {
      __typename
      id
    }
  }
`

const cleanupCodeSections = (codeSections: CodeSection[]): CodeSection[] => {
  return codeSections.map((section) => {
    const $ = cheerio.load(section.html)

    // Remove classes that shouldn't be persisted
    $('.clicked-element').removeClass('clicked-element')
    $('.hovered-element').removeClass('hovered-element')
    $('.clicked-code-section').removeClass('clicked-code-section')
    $('[contenteditable]').removeAttr('contenteditable')

    // Ensure the main container has the correct class and ID
    const mainContainer = $('body').children().first()
    mainContainer.addClass('code-section')
    if (!mainContainer.attr('id')) {
      mainContainer.attr('id', section.id)
    }

    return {
      ...section,
      html: $('body').html(),
    }
  })
}

const WebsiteChatPage = ({ id }: { id: string }) => {
  const websitePanelRef = useRef<WebsitePanelHandler>(null)
  const chatPanelRef = useRef<ChatPanelHandler>(null)
  const skipHistoryUpdateRef = useRef<boolean>(false)
  const isGeneratingWebsiteRef = useRef<boolean>(false)
  const favoriteImagePickerPanelRef =
    useRef<FavoriteImagePickerPanelHandler>(null)
  const isPickingFavoriteImagesRef = useRef<boolean>(false)
  const showedLoginComponentMessageRef = useRef<boolean>(false)
  const prevFinishedGeneratingAt = useRef<string>()

  const [clickedElement, setClickedElement] = useState<FrameElementEvent>(null)
  const [panelName, setPanelName] = useState('website')
  const [settingsPanelInitialTab, setSettingsPanelInitialTab] = useState('')
  const [clickedElementEvent, setClickedElementEvent] =
    useState<FrameElementEvent | null>(null)
  const [website, setWebsite] = useState<
    Website & {
      headerCodeSection: CodeSection
      footerCodeSection: CodeSection
    }
  >(null)
  const [activePage, setActivePage] = useState<PageWithCodeSections>(null)
  const [isDataChanged, setIsDataChanged] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [websiteWidth, setWebsiteWidth] = useState<WebsiteWidth>('desktop')
  const [history, setHistory] = useState([])
  const [historyIndex, setHistoryIndex] = useState(0)
  const [shouldDisableChatSubmit, setShouldDisableChatSubmit] = useState(false)
  const [isGalleryOpen, setIsGalleryOpen] = useState(false)
  const [isPublishing, setIsPublishing] = useState(false)
  const [galleryType, setGalleryType] = useState<
    'images' | 'videos' | 'illustrations'
  >('images')
  const [fonts, setFonts] = useState<string[]>(null)
  const [isOnboarding, setIsOnboarding] = useState<boolean>(null)
  const [gettingStartedState, setGettingStartedState] = useState<
    'edit' | 'setupDomain' | 'publish' | 'done'
  >('done')
  const [isDeletingPage, setIsDeletingPage] = useState(false)

  const { loading, error, refetch } = useQuery<FindWebsitePanelQuery>(
    FIND_WEBSITE,
    {
      variables: { id },
      onCompleted(data) {
        setWebsite(data.website as any)
        if (activePage) {
          setActivePage(
            (data.website.Pages as PageWithCodeSections[]).find(
              (v) => v.id === activePage.id
            )
          )
        }
      },
    }
  )
  const [saveWebsite] = useMutation(SAVE_WEBSITE)
  const [saveActivePage] = useMutation(SAVE_ACTIVE_PAGE)
  const [startBuildingWebsite] = useMutation(START_BUILDING_WEBSITE)
  const [startBuildingPage] = useMutation(START_BUILDING_PAGE)
  const [publishImageAndGetUrl] = useMutation(PUBLISH_IMAGE_AND_GET_URL)
  const [publishWebsite] = useMutation(PUBLISH_WEBSITE)
  const [claimWebsite] = useMutation(CLAIM_WEBSITE)
  const [deletePageMutation] = useMutation(DELETE_PAGE)

  const { isAuthenticated, loading: authLoading, logIn } = useAuth()

  useEffect(() => {
    if (
      history.length === 0 &&
      website &&
      website.finishedGeneratingAt &&
      activePage &&
      activePage.codeSections
    ) {
      setHistory([
        {
          activePage,
          headerCodeSection: website.headerCodeSection,
          footerCodeSection: website.footerCodeSection,
        },
      ])
    }
  }, [activePage, website])

  useEffect(() => {
    let intervalId
    if (activePage && !activePage.finishedGeneratingAt) {
      // if the page is still generating, keep refreshing
      refetch()
      intervalId = setInterval(() => {
        console.log('refetching active page...')
        if (activePage.finishedGeneratingAt) {
          clearInterval(intervalId)
        }
        refetch()
      }, 5000) // 5 second interval

      // Cleanup function that clears the interval when the component is unmounted or finishedGeneratingAt is not null
      return () => {
        clearInterval(intervalId)
        if (activePage.finishedGeneratingAt) {
          refetch()
        }
      }
    }

    if (activePage && activePage.finishedGeneratingAt && intervalId) {
      clearInterval(intervalId)
    }
  }, [activePage])

  useEffect(() => {
    if (authLoading) {
      return
    }

    if (!website) {
      return
    }

    if (website.finishedGeneratingAt && isAuthenticated) {
      setIsOnboarding(false)
    } else {
      setIsOnboarding(true)
    }
  }, [website?.finishedGeneratingAt, isAuthenticated, authLoading])

  useEffect(() => {
    if (!website) {
      return
    }

    //isOnboarding being undefined means we're still loading
    if (!isOnboarding && isOnboarding !== false) {
      //we're still loading
      return
    }

    if (website && !activePage) {
      setActivePage(
        (website.Pages as PageWithCodeSections[]).find((v) => v.isHomepage)
      )
      setFonts(website.fonts || defaultFonts)
    }

    if (website && !website.startedGeneratingAt) {
      if (isGeneratingWebsiteRef.current === true) {
        return // already generating
      }

      console.log('generating new website...')

      isGeneratingWebsiteRef.current = true
      // setShouldDisableChatSubmit(true)

      chatPanelRef.current.sendAssistantMessage(
        "Pick a template and I'll fill in the text and images according to your business."
      )

      setPanelName('templatePicker')
    }

    if (
      website &&
      website.startedGeneratingAt &&
      !website.finishedGeneratingAt &&
      isPickingFavoriteImagesRef.current === false
    ) {
      setPanelName('websiteLoading')
    }
  }, [website, isOnboarding])

  useEffect(() => {
    if (authLoading) {
      return
    }

    if (!website) {
      return
    }

    //if website.fininshedGeneratingAt is not null
    //go through all the website.Pages and check if any of them do not have startedGeneratingAt
    //if they don't, send a request to start generating those pages
    if (website && website.finishedGeneratingAt && isAuthenticated) {
      website.Pages.forEach(async (page) => {
        if (!page.startedGeneratingAt) {
          // await startBuildingPage({
          //   variables: {
          //     id: page.id,
          //   },
          // })
        }
      })
    }

    //if user is not logged in
    if (
      website &&
      website.finishedGeneratingAt &&
      !isAuthenticated &&
      !website.user
    ) {
      if (showedLoginComponentMessageRef.current === true) {
        return
      }

      showedLoginComponentMessageRef.current = true
      chatPanelRef.current.sendAssistantMessage(
        `Your website is done! You can change text, images, colors, add new sections, add new pages, and more... but first, you'll need to log in.`
      )
      chatPanelRef.current.showComponent('loginComponent')
    }

    if (prevFinishedGeneratingAt.current === undefined) {
      prevFinishedGeneratingAt.current = website.finishedGeneratingAt
      return
    }

    //in the flow of making the website
    if (
      website &&
      website.startedGeneratingAt &&
      prevFinishedGeneratingAt.current !== website.finishedGeneratingAt &&
      website.finishedGeneratingAt &&
      isAuthenticated
    ) {
      prevFinishedGeneratingAt.current = website.finishedGeneratingAt

      if (showedLoginComponentMessageRef.current === true) {
        return
      }

      showedLoginComponentMessageRef.current = true

      chatPanelRef.current.sendAssistantMessage(
        `Your website is done! You can change text, images, colors, add new sections, add new pages, and more...`
      )
    }
  }, [
    authLoading,
    isAuthenticated,
    website?.startedGeneratingAt,
    website?.finishedGeneratingAt,
  ])

  useEffect(() => {
    const asyncClaimWebsite = async () => {
      await claimWebsite({
        variables: {
          id: website.id,
        },
      })

      await refetch()
    }

    if (isAuthenticated && website && !website.user) {
      asyncClaimWebsite()
    }
  }, [website, isAuthenticated])

  useEffect(() => {
    console.log('in useEffect for website', website)
    if (!website) {
      return
    }

    if (
      panelName !== 'website' &&
      website.finishedGeneratingAt &&
      !website.hasCompletedOnboarding
    ) {
      onWebsiteFinishedGenerating()
    }

    // Check if finishedGeneratingAt is null, which means we need to keep refreshing
    if (
      website &&
      website.startedGeneratingAt &&
      !website.finishedGeneratingAt
    ) {
      console.log('refetching website...')
      const intervalId = setInterval(() => {
        if (website.finishedGeneratingAt) {
          clearInterval(intervalId)
          isGeneratingWebsiteRef.current = false
          onWebsiteFinishedGenerating()
        }

        refetch()
      }, 5000) // 5 second interval

      // Cleanup function that clears the interval when the component is unmounted or finishedGeneratingAt is not null
      return () => {
        clearInterval(intervalId)
        if (website.finishedGeneratingAt) {
          isGeneratingWebsiteRef.current = false
          onWebsiteFinishedGenerating()
        }
      }
    }
  }, [website?.startedGeneratingAt, website?.finishedGeneratingAt, refetch])

  useEffect(() => {
    // save page and website
    if (!isDataChanged) {
      return
    }
    setIsDataChanged(false)
    saveThisPage()
  }, [isDataChanged])

  useEffect(() => {
    if (!website) {
      return
    }

    if (
      !isOnboarding &&
      website.finishedGeneratingAt &&
      !website.customDomain &&
      !website.verifiedDomain &&
      !website.proPublishedAt
    ) {
      setGettingStartedState('edit')
    } else if (
      !isOnboarding &&
      website.finishedGeneratingAt &&
      website.customDomain &&
      !website.verifiedDomain &&
      !website.proPublishedAt
    ) {
      setGettingStartedState('setupDomain')
    } else if (
      !isOnboarding &&
      !website.finishedGeneratingAt &&
      website.customDomain &&
      website.verifiedDomain &&
      !website.proPublishedAt
    ) {
      setGettingStartedState('publish')
    } else if (website.proPublishedAt) {
      setGettingStartedState('done')
    }
  }, [
    isOnboarding,
    website,
    website?.finishedGeneratingAt,
    website?.customDomain,
    website?.verifiedDomain,
    website?.proPublishedAt,
  ])

  const onFrameElementClick = (event: FrameElementEvent) => {
    setClickedElement(event)
    setClickedElementEvent(event)
  }

  const clearClickedElement = () => {
    console.log('clearing clicked element in website chat page')
    setClickedElement(null)
    setClickedElementEvent(null)
    if (websitePanelRef.current) {
      websitePanelRef.current.clearClickedElement()
    }
  }

  const saveThisPage = async () => {
    if (isSaving) {
      return
    }
    setIsSaving(true)

    const { updatedPage, headerCodeSection, footerCodeSection } =
      websitePanelRef.current.getUpdatedPage()

    // Clean up code sections
    const cleanedCodeSections = cleanupCodeSections(updatedPage.codeSections)

    await saveActivePage({
      variables: {
        id: updatedPage.id,
        input: {
          codeSections: cleanedCodeSections,
        },
      },
    })

    const input: UpdateWebsiteInput = {}
    if (headerCodeSection) {
      input.headerCodeSection = cleanupCodeSections([headerCodeSection])[0]
    }
    if (footerCodeSection) {
      input.footerCodeSection = cleanupCodeSections([footerCodeSection])[0]
    }
    if (JSON.stringify(fonts) !== JSON.stringify(website.fonts)) {
      input.fonts = fonts
    }

    // only save if there is a change
    if (Object.keys(input).length > 0) {
      await saveWebsite({
        variables: {
          id: website.id,
          input: input,
        },
      })
    }

    if (skipHistoryUpdateRef.current === false) {
      setHistory((prevHistory) => {
        const newHistory = prevHistory.slice(0, historyIndex + 1)
        newHistory.push({
          activePage,
          headerCodeSection: website.headerCodeSection,
          footerCodeSection: website.footerCodeSection,
        })

        setHistoryIndex(newHistory.length - 1)
        return newHistory
      })
    }

    skipHistoryUpdateRef.current = false

    setIsSaving(false)
  }

  const updateHtmlFromUserPrompt = async (
    prompt: string
  ): Promise<{ message: string; ok: boolean }> => {
    const success = () => ({
      message: 'Website updated successfully',
      ok: true,
    })
    const failure = (error: string) => ({
      message: `Unable to update website: ${error}`,
      ok: false,
    })

    if (!clickedElementEvent) {
      return failure('No element selected')
    }

    if (!website.finishedGeneratingAt) {
      return failure(
        "Your website is still generating. Please try again once it's done"
      )
    }
    //send a request to getHtmlFromPrompt with prompt, and codeSection where clickedElementContent is the html of the element that was clicked

    const codeSectionElement = clickedElementEvent.codeSectionElement

    const codeSection = codeSectionElement
    const clickedElement = clickedElementEvent.element

    let apiResponse: Response

    try {
      apiResponse = await fetch(
        process.env.LAMBDA_GET_HTML_FROM_USER_PROMPT ||
          `${process.env.API_BASE_URL}/getHtmlFromUserPrompt`,
        {
          method: 'POST',
          body: JSON.stringify({
            id: website.id,
            prompt,
            codeSectionHtml: codeSection.outerHTML,
            clickedElementHtml: clickedElement.outerHTML,
          }),
        }
      )
    } catch (error) {
      console.log(error)
      return failure(error)
    }

    if (!apiResponse.ok) {
      console.log('error:', apiResponse)
      return failure(await apiResponse.text())
    }

    const res = await apiResponse.json()

    const updatedCodeSections = cleanupCodeSections([
      {
        id: codeSectionElement.id,
        html: res.data,
      },
    ])

    const updatedHtml = updatedCodeSections[0].html

    if (
      website.headerCodeSection &&
      website.headerCodeSection.id == codeSectionElement.id
    ) {
      setWebsite({
        ...website,
        headerCodeSection: {
          ...(website.headerCodeSection as CodeSection),
          html: updatedHtml,
        },
      })
    }

    setActivePage((prevPage) => ({
      ...prevPage,
      codeSections: prevPage.codeSections.map((section) => {
        if (section.id === codeSection.id) {
          return {
            ...section,
            html: updatedHtml,
          }
        } else {
          return section
        }
      }),
    }))

    if (
      website.footerCodeSection &&
      website.footerCodeSection.id == codeSectionElement.id
    ) {
      setWebsite({
        ...website,
        footerCodeSection: {
          ...(website.footerCodeSection as CodeSection),
          html: updatedHtml,
        },
      })
    }

    setIsDataChanged(true)
    clearClickedElement()
    return success()
  }

  const functions = {
    editHtmlOfSelectedElement: async (prompt: string) => {
      return await updateHtmlFromUserPrompt(prompt)
    },
    navigateToPanel: (toolResponse: string) => {
      let res = { panelName: 'website' }
      try {
        res = JSON.parse(toolResponse)
      } catch (e) {
        return {
          message: 'Error parsing tool response',
          ok: false,
        }
      }

      navigateToPanel(res.panelName)

      return {
        message: 'Navigated to settings panel',
        ok: true,
      }
    },
    addNewSectionAboveSelectedSection: async (toolResponse: string) => {
      let res = { sectionUserRequest: '' }
      try {
        res = JSON.parse(toolResponse)
      } catch (e) {
        return {
          message: 'Error parsing tool response',
          ok: false,
        }
      }

      const aiResponse = await createNewCodeSectionAbove(res.sectionUserRequest)

      return aiResponse
    },
    addNewSectionBelowSelectedSection: async (toolResponse: string) => {
      let res = { sectionUserRequest: '' }
      try {
        res = JSON.parse(toolResponse)
      } catch (e) {
        return {
          message: 'Error parsing tool response',
          ok: false,
        }
      }

      await createNewCodeSectionBelow(res.sectionUserRequest)

      return {
        message: 'Added new section below selected section',
        ok: true,
      }
    },
    openImageGallery: (galleryType) => {
      setGalleryType(galleryType)
      setIsGalleryOpen(true)
    },
  }

  const onSendMessageForUser = (message: string) => {
    console.log('onSendMessageForUser', message)
    // send message to user
  }

  const navigateToPanel = (panelName: string) => {
    console.log('navigateToPanel', panelName)
    isPickingFavoriteImagesRef.current = false
    setPanelName(panelName)
  }

  const changeViewportWidth = (
    size: 'mobile' | 'tablet' | 'laptop' | 'desktop'
  ) => {
    setWebsiteWidth(size)
  }

  const createNewPage = () => {
    onSendMessageForUser('I want to create a new page for my website')
  }

  const deletePage = async (pageToDelete) => {
    if (isDeletingPage) {
      return
    }

    setIsDeletingPage(true)
    await deletePageMutation({
      variables: {
        id: pageToDelete.id,
      },
    })

    if (activePage.id === pageToDelete.id) {
      const homepage = website.Pages.find((p) => p.isHomepage)
      setActivePage(homepage as PageWithCodeSections)
    }

    await refetch()

    setIsDeletingPage(false)
  }

  const changeActivePage = (page: PageWithCodeSections) => {
    setActivePage(page)
  }

  const onUndoClick = () => {
    console.log('undo')

    setHistoryIndex((prevIndex) => {
      if (prevIndex > 0) {
        const nextIndex = prevIndex - 1
        const historyElement = history[nextIndex]
        setWebsite((websiteVal) => {
          return {
            ...websiteVal,
            headerCodeSection: historyElement.headerCodeSection,
            footerCodeSection: historyElement.footerCodeSection,
          }
        })
        setActivePage(history[nextIndex].activePage)
        skipHistoryUpdateRef.current = true
        setIsDataChanged(true)
        return nextIndex
      }
      return prevIndex
    })
  }

  const onRedoClick = () => {
    console.log('redo')

    setHistoryIndex((prevIndex) => {
      if (prevIndex < history.length - 1) {
        const nextIndex = prevIndex + 1
        const historyElement = history[nextIndex]
        setWebsite((websiteVal) => {
          return {
            ...websiteVal,
            headerCodeSection: historyElement.headerCodeSection,
            footerCodeSection: historyElement.footerCodeSection,
          }
        })
        setActivePage(history[nextIndex].activePage)
        skipHistoryUpdateRef.current = true
        setIsDataChanged(true)
        return nextIndex
      }
      return prevIndex
    })
  }

  const onSaveClick = () => {
    saveThisPage()
  }

  const onSetUpYourDomainButtonClicked = () => {
    setPanelName('settings')
    setSettingsPanelInitialTab('Custom Domain')
  }

  const onAddNewCodeSectionAbove = (id: string) => {
    if (!id || !activePage || !activePage.codeSections) {
      return
    }

    chatPanelRef.current.sendUserMessage(
      "I'd like to add a new section above my selected section."
    )
  }

  const onAddNewCodeSectionBelow = (id: string) => {
    if (!id || !activePage || !activePage.codeSections) {
      return
    }

    chatPanelRef.current.sendUserMessage(
      "I'd like to add a new section below my selected section."
    )
  }

  const onMoveClickedCodeSectionUp = (id: string) => {
    if (!id || !activePage || !activePage.codeSections) {
      return
    }

    const codeSections = activePage.codeSections
    const index = codeSections.findIndex(
      (section: CodeSection) => section.id === id
    )

    if (index === 0) {
      return
    }

    const newCodeSections = [...codeSections]
    const temp = newCodeSections[index - 1]
    newCodeSections[index - 1] = newCodeSections[index]
    newCodeSections[index] = temp

    setActivePage((prevPage) => ({
      ...prevPage,
      codeSections: newCodeSections,
    }))

    setIsDataChanged(true)
  }

  const onMoveClickedCodeSectionDown = (id: string) => {
    if (!id || !activePage || !activePage.codeSections) {
      return
    }

    const codeSections = activePage.codeSections
    const index = codeSections.findIndex(
      (section: CodeSection) => section.id === id
    )

    if (index === activePage.codeSections.length - 1) {
      return
    }

    const newCodeSections = [...codeSections]
    const temp = newCodeSections[index + 1]
    newCodeSections[index + 1] = newCodeSections[index]
    newCodeSections[index] = temp

    setActivePage((prevPage) => ({
      ...prevPage,
      codeSections: newCodeSections,
    }))

    setIsDataChanged(true)
  }

  const onDeleteClickedCodeSection = (id: string) => {
    if (!id || !activePage || !activePage.codeSections) {
      return
    }

    const codeSections = activePage.codeSections
    const index = codeSections.findIndex(
      (section: CodeSection) => section.id === id
    )

    const newCodeSections = [...codeSections]
    newCodeSections.splice(index, 1)

    setActivePage((prevPage) => ({
      ...prevPage,
      codeSections: newCodeSections,
    }))
    setIsDataChanged(true)
  }

  const getNewCodeSection = async (prompt: string) => {
    let response: Response

    try {
      response = await fetch(
        process.env.LAMBDA_GET_NEW_CODE_SECTION_FROM_USER_PROMPT ||
          `${process.env.API_BASE_URL}/getNewCodeSectionFromUserPrompt`,
        {
          method: 'POST',
          body: JSON.stringify({
            id: website.id,
            pageId: activePage.id,
            prompt: prompt,
          }),
        }
      )
    } catch (error) {
      console.log(error)
      return
    }

    if (!response.ok) {
      console.log('error:', response)
      return
    }

    const res = await response.json()

    return res.data
  }

  const createNewCodeSectionAbove = async (sectionUserRequest: string) => {
    if (!activePage || !activePage.codeSections) {
      return {
        message:
          'Unable to add new section above selected section. Try refreshing the page',
        ok: false,
      }
    }

    if (!clickedElementEvent || !clickedElementEvent.codeSectionElement) {
      return {
        message: 'No section selected. Please select a section first',
        ok: false,
      }
    }

    const selectedSectionId = clickedElementEvent.codeSectionElement.id

    console.log('creating new code section above', selectedSectionId)
    console.log('sectionUserRequest:', sectionUserRequest)

    const newCodeSection = await getNewCodeSection(sectionUserRequest)

    const codeSections = activePage.codeSections
    const index = codeSections.findIndex(
      (section: CodeSection) => section.id === selectedSectionId
    )

    const newCodeSections = [...codeSections]
    newCodeSections.splice(index, 0, newCodeSection)

    setActivePage((prevPage) => ({
      ...prevPage,
      codeSections: newCodeSections,
    }))
    setIsDataChanged(true)

    return {
      message: 'Successfully created the new section',
      ok: true,
    }
  }

  const createNewCodeSectionBelow = async (sectionUserRequest: string) => {
    if (!activePage || !activePage.codeSections) {
      return {
        message:
          'Unable to add new section above selected section. Try refreshing the page',
        ok: false,
      }
    }

    if (!clickedElementEvent || !clickedElementEvent.codeSectionElement) {
      return {
        message: 'No section selected. Please select a section first',
        ok: false,
      }
    }

    const selectedSectionId = clickedElementEvent.codeSectionElement.id

    console.log(
      'clickedElementEvent.codeSectionElement.id:',
      clickedElementEvent.codeSectionElement.id
    )

    console.log('creating new code section below', selectedSectionId)
    console.log('sectionUserRequest:', sectionUserRequest)

    const newCodeSection = await getNewCodeSection(sectionUserRequest)

    const codeSections = activePage.codeSections
    const index = codeSections.findIndex(
      (section: CodeSection) => section.id === selectedSectionId
    )

    const newCodeSections = [...codeSections]
    newCodeSections.splice(index + 1, 0, newCodeSection)

    setActivePage((prevPage) => ({
      ...prevPage,
      codeSections: newCodeSections,
    }))
    setIsDataChanged(true)

    return {
      message: 'Successfully created the new section',
      ok: true,
    }
  }

  const onPageChange = (params: {
    page: PageWithCodeSections
    headerCodeSection: CodeSection
    footerCodeSection: CodeSection
  }) => {
    setActivePage(params.page)
    setWebsite((prevWebsite) => ({
      ...prevWebsite,
      headerCodeSection: params.headerCodeSection,
      footerCodeSection: params.footerCodeSection,
    }))
    setIsDataChanged(true)
  }

  const onPageFrameLinkClick = (path: string) => {
    const normalizedPath = path.startsWith('/') ? path.slice(1) : path
    const page = (website.Pages as PageWithCodeSections[]).find((page) => {
      return page.path === normalizedPath
    })
    if (page) {
      setActivePage(page)
    }
  }

  const handleMediaSelected = async (media: Media) => {
    setIsGalleryOpen(false)
    clearClickedElement()

    let element = clickedElementEvent.element

    // go ahead and replace the element with the media which has a low res url
    element = replaceClickedElementWithMedia({
      element,
      media,
      document: websitePanelRef.current.getDocument(),
    })

    // only upload if it's an image, video upload doesn't work yet
    if (media.type === 'image' || media.type === 'illustration') {
      try {
        const publishResponse = await publishImageAndGetUrl({
          variables: {
            id: website.id,
            input: {
              imageId: media.id,
              imageSource: media.src,
              // imageUrl is only used for upload
              imageUrl: media.src === 'upload' ? media.urls.display : '',
            },
          },
        })
        console.log('media upload response', publishResponse)
        // update the media src with the high res url and replace stuff again
        media.urls.display = publishResponse.data.publishImageAndGetUrl

        replaceClickedElementWithMedia({
          element,
          media,
          document: websitePanelRef.current.getDocument(),
        })
      } catch (error) {
        console.log('Failed to upload media', error, media)
      }
    }

    let newImageUrl = media.urls.small.image
    if (media.type === 'image' || media.type === 'illustration') {
      newImageUrl = media.src
    }

    const newSeoMetaTags = getUpdatedSeoImageTags({
      element: element as HTMLImageElement,
      activePage,
      newImageUrl,
    })

    setActivePage((prevPage) => ({
      ...prevPage,
      codeSections:
        websitePanelRef.current.getUpdatedPage().updatedPage.codeSections,
      seoMetaTags: newSeoMetaTags,
    }))

    setIsDataChanged(true)
  }

  const onTemplatePicked = async (templateId: string) => {
    startBuildingWebsite({
      variables: {
        id: website.id,
        templateId: templateId,
      },
    })

    setWebsite((prevWebsite) => ({
      ...prevWebsite,
      startedGeneratingAt: new Date().toISOString(),
    }))

    chatPanelRef.current.sendAssistantMessage(
      `I'm generating your website. This may take a few minutes.`
    )

    setPanelName('websiteLoading')
  }

  const onPickFavoriteImagesSelected = () => {
    setPanelName('favoriteImagePicker')
    isPickingFavoriteImagesRef.current = true
  }

  const onWebsiteFinishedGenerating = async () => {
    console.log('website finished generating')

    if (isPickingFavoriteImagesRef.current === false) {
      setPanelName('website')
    }

    setWebsite((prevWebsite) => ({
      ...prevWebsite,
      hasCompletedOnboarding: true,
    }))

    await saveWebsite({
      variables: {
        id: website.id,
        input: {
          hasCompletedOnboarding: true,
        },
      },
    })
  }

  const onShowWebsiteClicked = () => {
    const pickedImages = [
      ...favoriteImagePickerPanelRef.current.getSelectedImages(),
    ]
    const pickedIllustrations = [
      ...favoriteImagePickerPanelRef.current.getSelectedIllustrations(),
    ]
    console.log('pickedImages:')
    console.log([...pickedImages])
    console.log('pickedIllustrations:')
    console.log([...pickedIllustrations])

    // if (website.headerCodeSection) {
    //   const headerDom = cheerio.load(website.headerCodeSection.html)
    //   headerDom('img').each((index, element) => {
    //     const src = headerDom(element).attr('src')
    //     if (src && src.includes('unsplash')) {
    //       headerDom(element).attr('src', pickedImages.shift())
    //     }
    //   })
    // }

    const hasPickedImages = pickedImages && pickedImages.length > 0
    const hasPickedIllustrations =
      pickedIllustrations && pickedIllustrations.length > 0

    if (hasPickedImages || hasPickedIllustrations) {
      const newCodeSections = []
      activePage.codeSections.forEach((codeSection: CodeSection) => {
        console.log('in code section')
        const $ = cheerio.load(codeSection.html)
        $('*').each((index, element) => {
          if ($(element).attr('data-dont-replace') !== undefined) {
            return
          }

          if ($(element).attr('data-testimonial-image') !== undefined) {
            return
          }

          let hasBackgroundImage = false
          const styleBackgroundImage = $(element).css('background-image')
          const classList = $(element).attr('class') || ''

          // Check if the element has an inline background-image style
          if (styleBackgroundImage && styleBackgroundImage !== 'none') {
            hasBackgroundImage = true
          }

          // Check if the class attribute contains a Tailwind bg-[url(...)] class
          const match = classList.match(/bg-\[url\(['"]?([^'"\]]+?)['"]?\)\]/)
          if (match) {
            hasBackgroundImage = true
          }

          if (hasBackgroundImage) {
            let pickedMedia
            if ($(element).attr('data-landingsite-gallery-type') === 'image') {
              pickedMedia = pickedImages.shift()
            }

            if (
              $(element).attr('data-landingsite-gallery-type') ===
              'illustration'
            ) {
              pickedMedia = pickedIllustrations.shift()
            }

            if (!pickedMedia) {
              return
            }

            console.log('has bg-url backgroundimage')
            console.log('replacing it with ', pickedMedia.urls.display)
            // Replace the background image with the first picked image
            // $(element).css('background-image', 'none')
            const updatedClassList = classList
              .replace(/bg-\[url\(['"]?([^'"\]]+?)['"]?\)\]/, '')
              .trim()
            $(element).attr('class', updatedClassList)

            $(element).addClass(`bg-[url('${pickedMedia.urls.display}')]`)
            $(element).attr('data-media', pickedMedia.dataMedia)
          }
        })

        $('img').each((index, element) => {
          console.log('found img')
          if ($(element).attr('data-dont-replace') !== undefined) {
            console.log('has data-dont-replace')
            return
          }

          if ($(element).attr('data-testimonial-image') !== undefined) {
            console.log('has data-testimonial-image')
            return
          }

          let pickedMedia
          if ($(element).attr('data-landingsite-gallery-type') === 'image') {
            pickedMedia = pickedImages.shift()
          }

          if (
            $(element).attr('data-landingsite-gallery-type') === 'illustration'
          ) {
            pickedMedia = pickedIllustrations.shift()
          }

          if (!pickedMedia) {
            console.log('no picked illustration')
            console.log(pickedIllustrations)
            return
          }

          console.log('replacing image with ', pickedMedia.urls.display)
          $(element).attr('src', pickedMedia.urls.display)
          $(element).attr('data-media', pickedMedia.dataMedia)
        })

        const newHtml = $('body').html()

        newCodeSections.push({
          ...codeSection,
          html: newHtml,
        })
      })

      setActivePage((prevPage) => ({
        ...prevPage,
        codeSections: newCodeSections,
      }))

      setIsDataChanged(true)
    }
    isPickingFavoriteImagesRef.current = false
    setPanelName('website')
  }

  const onPublishClicked = async () => {
    if (isPublishing) {
      return
    }

    setIsPublishing(true)

    //right now, publish to free domain for testing purposes
    //once domain stuff is working with the new chat we'll publish to pro domain
    try {
      await publishWebsite({
        variables: {
          id: website.id,
          publishToFreeDomain: true,
          publishToProDomain: false,
        },
      })
    } catch (e) {
      console.log(e)
    }

    setIsPublishing(false)
  }

  const handleFontChange = (font: Font | null, element: HTMLElement) => {
    // Remove existing font-family classes
    element.classList.remove(
      ...Array.from(element.classList).filter((cls) =>
        cls.startsWith('[font-family')
      )
    )

    if (!font) {
      // If font is null, we've already removed the font class
      return
    }

    // Apply the font class to the element
    const fontFamily = font.family.replace(/\s+/g, '_')
    const fontClass = `[font-family:${fontFamily}]`
    element.classList.add(fontClass)

    const uniqueFonts = new Set([...fonts, font.family])
    const newFonts = Array.from(uniqueFonts)

    // Update the font link in the iframe
    const iframe = websitePanelRef.current.getDocument()
    const fontLink = iframe.head.querySelector(
      '#google-fonts-link'
    ) as HTMLLinkElement

    fontLink.href = buildFontUrl(newFonts)
    setFonts(newFonts)

    // Trigger a save
    setIsDataChanged(true)
  }

  const fontSizeClasses = [
    'text-xs',
    'text-sm',
    'text-base',
    'text-lg',
    'text-xl',
    'text-2xl',
    'text-3xl',
    'text-4xl',
    'text-5xl',
    'text-6xl',
    'text-7xl',
    'text-8xl',
    'text-9xl',
  ]

  const getCurrentBreakpoint = () => {
    switch (websiteWidth) {
      case 'desktop':
        return '2xl'
      case 'laptop':
        return 'xl'
      case 'tablet':
        return 'md'
      case 'mobile':
        return ''
      default:
        return ''
    }
  }

  const handleFontWeightChange = (weight: string, element: HTMLElement) => {
    const breakpoint = getCurrentBreakpoint()

    // Remove existing font-size classes for current breakpoint
    const classesToRemove = fontSizeClasses.map((cls) =>
      breakpoint ? `${breakpoint}:${cls}` : cls
    )
    element.classList.remove(
      ...classesToRemove.filter((cls) => element.classList.contains(cls))
    )

    // Add new font-size class with breakpoint prefix
    const newClass = breakpoint ? `${breakpoint}:${weight}` : weight
    element.classList.add(newClass)

    // Trigger a save
    setIsDataChanged(true)
  }

  const handleFontStyleChange = (
    style: 'bold' | 'italic' | 'underline',
    element: HTMLElement
  ) => {
    switch (style) {
      case 'bold':
        element.classList.toggle('font-bold')
        break
      case 'italic':
        element.classList.toggle('italic')
        break
      case 'underline':
        element.classList.toggle('underline')
        break
    }
    // Trigger a save
    setIsDataChanged(true)
  }

  const handleLinkChange = (href: string | null, element: HTMLElement) => {
    if (!element) return

    if (href) {
      // If href is provided, wrap the element in an <a> tag or update existing link
      const linkElement = element.closest('a') || document.createElement('a')
      linkElement.href = href
      if (element.parentNode !== linkElement) {
        element.parentNode.insertBefore(linkElement, element)
        linkElement.appendChild(element)
      }
    } else {
      // If href is null, remove the link
      const linkElement = element.closest('a')
      if (linkElement) {
        const parent = linkElement.parentNode
        while (linkElement.firstChild) {
          parent.insertBefore(linkElement.firstChild, linkElement)
        }
        parent.removeChild(linkElement)
      }
    }
    // Trigger a save
    setIsDataChanged(true)

    // update the clicked element to be the wrapping anchor tag, or the element itself if no href
    websitePanelRef.current.setClickedElement(element)
  }

  return (
    <>
      <Metadata title="EditWebsite" description="Edit Website" />
      <main className="flex h-full w-full flex-col overflow-hidden bg-gray-100">
        <Navbar
          buttons={['settings', 'publish', 'user']}
          website={website}
          navigateToPanel={navigateToPanel}
          onPublishClicked={onPublishClicked}
        />

        <div className="flex h-full w-full flex-row overflow-hidden">
          {/* chat panel */}
          <div className="w-1/4 min-w-[400px] flex-none overflow-hidden p-4">
            <div className="relative flex h-full w-full flex-col rounded bg-white pb-4 shadow-md">
              {website && (
                <ChatPanel
                  ref={chatPanelRef}
                  websiteId={id}
                  functions={functions}
                  clickedElement={clickedElement}
                  handleClearClickedElement={clearClickedElement}
                  disableChatSubmit={shouldDisableChatSubmit}
                  onMediaUploaded={handleMediaSelected}
                  isOnboarding={isOnboarding}
                  gettingStartedState={gettingStartedState}
                  onSetUpYourDomainButtonClicked={
                    onSetUpYourDomainButtonClicked
                  }
                />
              )}
            </div>
          </div>

          {/* right panel */}
          <div className="grow overflow-hidden p-4 pl-0">
            <div className="flex h-full w-full flex-col">
              {loading && !website && (
                <div className="pt-12 text-center">
                  <i className="fa-regular fa-spinner-third fa-spin"></i>
                </div>
              )}
              {!loading && !website && !error && (
                <div className="pt-12 text-center">
                  <div className="mb-4 text-4xl">404 - Website not found</div>
                  <div>
                    <a href="/" className="text-xl text-blue-500">
                      Go back to dashboard
                    </a>
                  </div>
                </div>
              )}
              {!loading &&
                error &&
                error.message === 'You must log in to see this page' && (
                  <div className="pt-12 text-center">
                    <div className="mb-4 text-3xl">
                      You must log in to see this page
                    </div>
                    <div>
                      <button
                        className="rounded bg-blue-500 px-4 py-2 text-white"
                        onClick={() => {
                          logIn({
                            appState: {
                              targetUrl: `/login-success?redirectTo=${encodeURIComponent(
                                window.location.pathname +
                                  window.location.search
                              )}`,
                            },
                          })
                        }}
                      >
                        Log in
                      </button>
                    </div>
                  </div>
                )}
              {!loading &&
                error &&
                error.message !== 'You must log in to see this page' && (
                  <>
                    <div>
                      Sorry, there was an error loading your website. Try
                      refreshing the page.
                    </div>
                  </>
                )}
              {panelName === 'website' && website && activePage && (
                <>
                  <PageToolbar
                    website={website}
                    activePage={activePage}
                    element={clickedElementEvent?.element}
                    onViewportWidthChange={changeViewportWidth}
                    onNewPageClicked={createNewPage}
                    onDeletePageClicked={deletePage}
                    changeActivePage={changeActivePage}
                    onUndoClick={onUndoClick}
                    onRedoClick={onRedoClick}
                    onSaveClick={onSaveClick}
                    isWebsiteSaving={isSaving}
                    undoDisabled={historyIndex === 0}
                    redoDisabled={
                      history.length === 0 ||
                      (history.length > 0 &&
                        historyIndex === history.length - 1)
                    }
                    onFontChange={handleFontChange}
                    onFontWeightChange={handleFontWeightChange}
                    onFontStyleChange={handleFontStyleChange}
                    onLinkChange={handleLinkChange}
                  />
                  <div className="relative flex-1 overflow-hidden">
                    <div
                      className={`absolute inset-0 ${
                        isGalleryOpen ? 'invisible' : ''
                      }`}
                    >
                      <WebsitePanel
                        ref={websitePanelRef}
                        website={website}
                        activePage={activePage}
                        websiteWidth={websiteWidth}
                        onFrameElementClick={onFrameElementClick}
                        onAddNewCodeSectionAbove={onAddNewCodeSectionAbove}
                        onAddNewCodeSectionBelow={onAddNewCodeSectionBelow}
                        onMoveClickedCodeSectionUp={onMoveClickedCodeSectionUp}
                        onMoveClickedCodeSectionDown={
                          onMoveClickedCodeSectionDown
                        }
                        onDeleteClickedCodeSection={onDeleteClickedCodeSection}
                        onPageChange={onPageChange}
                        onPageFrameLinkClick={onPageFrameLinkClick}
                      />
                    </div>
                    {isGalleryOpen && (
                      <div className="absolute inset-0 z-10 flex-1 overflow-auto">
                        <GalleryPanel
                          onClose={() => {
                            setIsGalleryOpen(false)
                          }}
                          onMediaSelected={handleMediaSelected}
                          initialGalleryType={galleryType}
                          website={website}
                          activePage={activePage}
                        />
                      </div>
                    )}
                  </div>
                </>
              )}
              {panelName === 'settings' && website && (
                <>
                  <div className="flex-1 overflow-auto">
                    <SettingsPanel
                      website={website}
                      initialTab={settingsPanelInitialTab}
                      onClose={() => navigateToPanel('website')}
                      onClickOpenDomainSearch={() => {
                        setPanelName('domainSearch')
                      }}
                    />
                  </div>
                </>
              )}
              {panelName === 'templatePicker' && website && (
                <>
                  <div className="flex-1 overflow-auto">
                    <TemplatePickerPanel onTemplatePicked={onTemplatePicked} />
                  </div>
                </>
              )}
              {panelName === 'websiteLoading' && website && (
                <>
                  <WebsiteLoadingProgressBar
                    website={website}
                    activePage={activePage}
                    onShowWebsiteClicked={onShowWebsiteClicked}
                  />
                  <div className="flex-1 overflow-auto">
                    <WebsiteLoadingPanel
                      onPickFavoriteImagesSelected={
                        onPickFavoriteImagesSelected
                      }
                    />
                  </div>
                </>
              )}
              {panelName === 'favoriteImagePicker' && website && (
                <>
                  <WebsiteLoadingProgressBar
                    website={website}
                    activePage={activePage}
                    onShowWebsiteClicked={onShowWebsiteClicked}
                  />
                  <div className="flex-1 overflow-auto">
                    <FavoriteImagePickerPanel
                      ref={favoriteImagePickerPanelRef}
                      websiteId={website.id}
                    />
                  </div>
                </>
              )}
              {panelName === 'domainSearch' && website && (
                <>
                  <DomainSearchPanel
                    website={website}
                    onClose={() => navigateToPanel('settings')}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </main>
    </>
  )
}

export default WebsiteChatPage

import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'

import { useMutation } from '@redwoodjs/web'

const GET_IMAGES_FROM_NEW_IMAGES_KEYWORD = gql`
  mutation GetImagesFromNewImagesKeywordMutation(
    $id: String!
    $imagesKeyword: String
  ) {
    getImagesFromNewImagesKeyword(id: $id, imagesKeyword: $imagesKeyword)
  }
`

type FavoriteImagePickerPanelProps = {
  websiteId: string
}

export type FavoriteImagePickerPanelHandler = {
  getSelectedImages: () => JSON[]
  getSelectedIllustrations: () => JSON[]
}

const FavoriteImagePickerPanel = forwardRef(
  ({ websiteId }: FavoriteImagePickerPanelProps, ref) => {
    // give the parent a hook into the panel
    useImperativeHandle<unknown, FavoriteImagePickerPanelHandler>(ref, () => {
      return {
        getSelectedImages: getSelectedImages,
        getSelectedIllustrations: getSelectedIllustrations,
      }
    })

    const [selectedImages, setSelectedImages] = useState([])
    const [selectedIllustrations, setSelectedIllustrations] = useState([])
    const [imageResults, setImageResults] = useState([])
    const [illustrationResults, setIllustrationResults] = useState([])
    const [resultsIndex, setResultsIndex] = useState(0)
    const [isPickingImages, setIsPickingImages] = useState(true)
    const [isDonePicking, setIsDonePicking] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const [results, setResults] = useState([])

    const [getImagesFromNewImagesKeyword] = useMutation(
      GET_IMAGES_FROM_NEW_IMAGES_KEYWORD
    )

    useEffect(() => {
      const loadImageResults = async () => {
        const res = await getImagesFromNewImagesKeyword({
          variables: {
            id: websiteId,
          },
        })

        setResults(res.data.getImagesFromNewImagesKeyword.results)
        setIsLoading(false)
      }

      setIsLoading(true)
      loadImageResults()
    }, [])

    useEffect(() => {
      if (results && results.length > 0) {
        setImageResults(results[resultsIndex].images.results)
        setIllustrationResults(results[resultsIndex].illustrations.results)
      }
    }, [results])

    const getSelectedImages = () => {
      return Array.from(selectedImages)
    }

    const getSelectedIllustrations = () => {
      return Array.from(selectedIllustrations)
    }

    let imagesToDisplay = imageResults

    if (isPickingImages === false) {
      imagesToDisplay = illustrationResults
    }

    return (
      <>
        {isLoading && (
          <div className="mt-12 flex items-center justify-center">
            <i className="fa-regular fa-spinner-third fa-spin text-2xl"></i>
          </div>
        )}
        {isDonePicking && !isLoading && (
          <div className="mt-12 flex items-center justify-center">
            <i className="fa-regular fa-check-circle text-4xl text-green-500"></i>
          </div>
        )}
        {!isDonePicking && !isLoading && (
          <div className="overflow-scroll rounded bg-white p-2 shadow-md">
            <h1 className="my-4 text-center text-4xl">
              Pick your favorite {isPickingImages ? 'images' : 'illustrations'}{' '}
              (Optional)
            </h1>
            <div className="flex items-center justify-center">
              <button
                className="mb-4 mr-4 rounded bg-blue-500 px-4 py-2 text-white hover:bg-blue-600"
                onClick={() => {
                  setResultsIndex((prevIndex) => {
                    if (prevIndex === results.length - 1) {
                      prevIndex = -1
                    }

                    setImageResults(results[prevIndex + 1].images.results)
                    setIllustrationResults(
                      results[prevIndex + 1].illustrations.results
                    )
                    return prevIndex + 1
                  })
                }}
              >
                Show Me Different {isPickingImages ? 'Images' : 'Illustrations'}
              </button>
              <button
                className="mb-4 rounded bg-blue-500 px-4 py-2 text-white hover:bg-blue-600"
                onClick={() => {
                  if (isPickingImages === false) {
                    setIsDonePicking(true)
                  }
                  setIsPickingImages(false)
                }}
              >
                Done
              </button>
            </div>
            <div className="grid grid-cols-5 gap-2">
              {imagesToDisplay.map((image) => (
                <button
                  key={image.id}
                  onClick={() => {
                    if (isPickingImages === false) {
                      if (
                        selectedIllustrations.find((img) => img.id === image.id)
                      ) {
                        setSelectedIllustrations(
                          selectedIllustrations.filter(
                            (img) => img.id !== image.id
                          )
                        )
                        return
                      }
                      setSelectedIllustrations([
                        ...selectedIllustrations,
                        image,
                      ])
                    } else {
                      if (selectedImages.find((img) => img.id === image.id)) {
                        setSelectedImages(
                          selectedImages.filter((img) => img.id !== image.id)
                        )
                        return
                      }
                      setSelectedImages([...selectedImages, image])
                    }
                  }}
                  className="relative max-h-32 bg-gray-200"
                >
                  <div className="absolute bottom-0 right-2">
                    <i
                      className={
                        (selectedImages.find((img) => img.id === image.id) ||
                        selectedIllustrations.find((img) => img.id === image.id)
                          ? 'fa-solid text-4xl'
                          : 'fa-regular text-2xl') +
                        ' fa-heart text-white transition-[font-size]'
                      }
                    ></i>
                  </div>
                  <img
                    src={image.urls.small}
                    className="h-full w-full object-contain"
                    alt=""
                    draggable={false}
                  />
                </button>
              ))}
            </div>
          </div>
        )}
      </>
    )
  }
)

export default FavoriteImagePickerPanel

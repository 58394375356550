import { useEffect, useState } from 'react'

import type { Website } from 'types/graphql'

import { useMutation } from '@redwoodjs/web'

import { useAuth } from 'src/auth'

const SEARCH_DOMAINS = gql`
  mutation SearchDomainsMutation($id: String!, $domainName: String!) {
    searchDomains(id: $id, domainName: $domainName) {
      __typename
      domainName
      sld
      tld
      purchasable
      purchasePrice
      purchaseType
      renewalPrice
    }
  }
`

type DomainSearchPanelProps = {
  website: Website
  onClose: () => void
}

const DomainSearchPanel = ({
  website: websiteProp,
  onClose,
}: DomainSearchPanelProps) => {
  const [website, setWebsite] = useState<Website>(websiteProp)
  const { isAuthenticated, logIn, signUp, loading } = useAuth()
  const [domainName, setDomainName] = useState('')
  const [isDomainSearchLoading, setIsDomainSearchLoading] = useState(false)
  const [listOfDomains, setListOfDomains] = useState([])
  const [domainToPurchase, setDomainToPurchase] = useState(null)

  const [searchDomains] = useMutation(SEARCH_DOMAINS)

  useEffect(() => {
    if (domainToPurchase) {
      console.log('Domain to purchase:', domainToPurchase)
      // chatPurchaseDomain({
      //   variables: {
      //     id: website.id,
      //     domainName: domainToPurchase.domainName,
      //     sld: domainToPurchase.sld,
      //     tld: domainToPurchase.tld,
      //     purchasePrice: domainToPurchase.purchasePrice,
      //     purchaseType: domainToPurchase.purchaseType,
      //     renewalPrice: domainToPurchase.renewalPrice,
      //   },
      // })
    }
  }, [domainToPurchase])

  const handleDomainSearch = async (e) => {
    if (e) {
      e.preventDefault()
    }

    // Simulated search
    setIsDomainSearchLoading(true)
    const res = await searchDomains({
      variables: {
        id: website.id,
        domainName,
      },
    })

    console.log(res)

    setListOfDomains(res.data.searchDomains)
    console.log(res.data.searchDomains)
    setIsDomainSearchLoading(false)
  }

  if (!loading && !isAuthenticated) {
    return (
      <div className="overflow-scroll rounded bg-white p-2 shadow-md">
        <div className="p-4 text-center">
          <div className="text-lg font-semibold">Sign Up or Log In</div>
          <div className="text-xs text-gray-400">
            Sign up or log in to purchase a domain
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="overflow-scroll rounded bg-white p-2 shadow-md">
      <div className="mb-4 rounded bg-yellow-100 p-4 text-center text-yellow-800">
        <strong>Limited time offer:</strong> Get your first month{' '}
        <span className="font-bold">FREE</span> when you purchase a domain
        through Landingsite.ai!
      </div>
      <div className="h-screen p-4">
        <div className="font-semibold">Search For Your Domain</div>
        <div className="text-xs text-gray-400">
          After you purchase a domain, we will set everything up automatically
        </div>
        <form onSubmit={handleDomainSearch} className="mt-4 w-full">
          <div className="flex">
            <input
              type="text"
              className="max-w-96 flex-grow rounded border border-gray-200 p-2"
              placeholder="mydomain.com"
              value={domainName}
              onChange={(e) => setDomainName(e.target.value)}
            />

            <button
              type="submit"
              className="ml-2 rounded bg-blue-500 p-2 text-white"
            >
              Search
            </button>
          </div>
        </form>
        {isDomainSearchLoading ? (
          <div className="mt-4 flex justify-center">
            <div className="fa-regular fa-spinner-third animate-spin"></div>
          </div>
        ) : (
          <ul className="mt-4">
            {listOfDomains.map((domain, index) => (
              <li
                key={index}
                className="flex items-center justify-between border-b pb-2 pt-2"
              >
                <span>{domain.domainName}</span>
                {!domain.purchasable && (
                  <span className="text-red-500">Not Available</span>
                )}
                {domain.purchasable && (
                  <div className="flex flex-row items-center">
                    {(!domain.renewalPrice ||
                      domain.renewalPrice === domain.purchasePrice) && (
                      <>
                        <span className="">${domain.purchasePrice}</span>
                        <span className="mr-2 text-xs">/year</span>
                      </>
                    )}

                    {domain.renewalPrice &&
                      domain.renewalPrice != domain.purchasePrice && (
                        <span>
                          <div className="mr-2 flex flex-col">
                            <div className="text-right">
                              ${domain.purchasePrice}
                            </div>
                            <div className="text-sm text-gray-500">
                              <span className="mr-1">Renews at</span>
                              <span>${domain.renewalPrice}</span>
                              <span className="text-xs">/year</span>
                            </div>
                          </div>
                        </span>
                      )}

                    <button
                      className="rounded bg-green-500 px-4 py-2 text-white hover:bg-green-700"
                      onClick={() => {
                        setDomainToPurchase(domain)
                      }}
                    >
                      Purchase
                    </button>
                  </div>
                )}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  )
}

export default DomainSearchPanel

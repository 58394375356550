import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'

import * as cheerio from 'cheerio'
import Frame from 'react-frame-component'
import { Page } from 'types/graphql'

import { useAuth } from 'src/auth'

import { getInitialContent } from './initialContent'

export type PageFrameHandler = {
  getBoundingClientRect: () => DOMRect
  getContentWindow: () => Window
  getUpdatedPage: () => {
    updatedPage: PageWithCodeSections
    headerCodeSection: CodeSection
    footerCodeSection: CodeSection
  }
  adjustHeight: () => void
  clearClickedElement: () => void
  setClickedElement: (element: HTMLElement) => void
}

export type CodeSection = {
  id: string
  html: string
}

export type JSONValue =
  | string
  | number
  | boolean
  | { [x: string]: JSONValue }
  | Array<JSONValue>

// codeSections is a JSONValue type, typing it into what we know it is
export type PageWithCodeSections = Page & {
  codeSections: CodeSection[]
}

export type FrameElementEvent = {
  element: HTMLElement
  codeSectionElement: HTMLElement
  imageSrc: string
}

type Props = {
  className?: string
  page: Page & {
    codeSections: CodeSection[]
  }
  headerCodeSection: CodeSection
  footerCodeSection: CodeSection
  colorPalette?: JSONValue
  fonts?: string[]
  onElementHover?: (event: FrameElementEvent) => void
  onElementClick?: (event: FrameElementEvent) => void
  // pass updated page and have parent save it
  onPageChange?: (params: {
    page: Page
    headerCodeSection: CodeSection
    footerCodeSection: CodeSection
  }) => void
  onAddNewCodeSectionAbove?: (id: string) => void
  onAddNewCodeSectionBelow?: (id: string) => void
  onMoveClickedCodeSectionUp?: (id: string) => void
  onMoveClickedCodeSectionDown?: (id: string) => void
  onDeleteClickedCodeSection?: (id: string) => void
  isNewChatPageFrame?: boolean
  isInEditMode?: boolean
  onPageFrameLinkClick?: (path: string) => void
}

const PageFrame = forwardRef(
  (
    {
      className,
      page,
      headerCodeSection,
      footerCodeSection,
      colorPalette,
      fonts,
      onElementClick,
      onElementHover,
      onPageChange,
      onAddNewCodeSectionAbove,
      onAddNewCodeSectionBelow,
      onMoveClickedCodeSectionUp,
      onMoveClickedCodeSectionDown,
      onDeleteClickedCodeSection,
      isNewChatPageFrame,
      isInEditMode,
      onPageFrameLinkClick,
    }: Props,
    ref
  ) => {
    const { isAuthenticated, loading } = useAuth()

    const iframeRef = useRef<HTMLIFrameElement>(null)

    isInEditMode = isInEditMode ?? true

    // give the parent a hook into the iframe
    useImperativeHandle<unknown, PageFrameHandler>(
      ref,
      () => {
        return {
          getBoundingClientRect: () => {
            return iframeRef.current.getBoundingClientRect()
          },
          getContentWindow: () => {
            return iframeRef.current.contentWindow
          },
          getUpdatedPage: () => {
            return getUpdatedPage()
          },
          adjustHeight: () => {
            return adjustHeight()
          },
          clearClickedElement: () => {
            console.log('clearing clicked element in page frame')
            setClickedElement(null)
          },
          setClickedElement: (element: HTMLElement) => {
            setClickedElement(element)
          },
        }
      },
      [iframeRef, page]
    )

    const addedEvents = useRef(false)
    const editableElementText = useRef<string>(null)

    const [hoveredElement, setHoveredElement] = useState<HTMLElement>(null)
    const [previousHoveredElement, setPreviousHoveredElement] =
      useState<HTMLElement>(null)
    const [clickedElement, setClickedElement] = useState<HTMLElement>(null)
    const [previousClickedElement, setPreviousClickedElement] =
      useState<HTMLElement>(null)
    const [cssVariables] = useState<string>(
      colorPalette
        ? Object.entries(colorPalette)
            .map(([key, value]) => {
              if (key.includes('font')) {
                value = (value as string).replace(/['"]/g, '')
              }
              return `--${key}: ${value};`
            })
            .join(' ')
        : ''
    )
    const [clickedCodeSection, setClickedCodeSection] =
      useState<HTMLElement>(null)
    const [previousClickedCodeSection, setPreviousClickedCodeSection] =
      useState<HTMLElement>(null)

    const clickHandlerRef = useRef(null)

    const adjustHeight = () => {
      if (iframeRef.current) {
        const iframeDocument = iframeRef.current.contentWindow.document
        const frameRoot = iframeDocument.querySelector(
          '.frame-root'
        ) as HTMLElement
        if (frameRoot) {
          const frameRootHeight = frameRoot.offsetHeight
          iframeRef.current.style.height = `${frameRootHeight + 10}px` //+10 to get rid of very small scroll issue
        }
      }
    }

    useEffect(() => {
      const iframeDocument = iframeRef.current?.contentWindow?.document
      // Clean up the clickHandlerRef listener when the component unmounts
      return () => {
        if (iframeDocument && clickHandlerRef.current) {
          iframeDocument.removeEventListener('click', clickHandlerRef.current)
        }
      }
    }, [])

    const contentDidMount = () => {
      adjustHeight()
      //this is a hack to make sure the height is correct
      //we have to wait a little to make sure the height is correct
      //but if we wait too long, the page rubber bands back to the top on scroll
      //we also run adjustHeight constantly on contentDidUpdate of the iframe
      setTimeout(adjustHeight, 100)
      setTimeout(adjustHeight, 500)
      setTimeout(adjustHeight, 1000)

      const iframeDocument = iframeRef.current.contentWindow.document
      if (!addedEvents.current) {
        iframeDocument.addEventListener('mouseover', handleMouseOver)
        iframeDocument.addEventListener('click', handleMouseClick)
        addedEvents.current = true
      }

      clickHandlerRef.current = (event) => {
        const target = event.target.closest('a')
        if (target && target.href) {
          const currentHost = window.location.host
          const targetUrl = new URL(target.href)
          const targetHost = targetUrl.host

          if (targetHost === currentHost) {
            event.preventDefault()

            const path = targetUrl.pathname

            onPageFrameLinkClick && onPageFrameLinkClick(path)
          }
        }
      }

      if (!isInEditMode) {
        console.log('not in edit mode, adding click event listener')
        iframeDocument.addEventListener('click', clickHandlerRef.current)
      }
    }

    const getElementContext = useCallback((element: HTMLElement) => {
      let image

      if (element.tagName.toLowerCase() === 'img') {
        image = (element as HTMLImageElement).src
      } else if (
        element.style.backgroundImage &&
        element.style.backgroundImage !== 'none' &&
        element.style.backgroundImage !== 'initial'
      ) {
        image = element.style.backgroundImage.split('"')[1].split('"')[0]
      } else if (element.tagName.toLowerCase() === 'video') {
        image = (element as HTMLVideoElement).poster
      }

      if (element && element.className && element.className.match) {
        const match = element.className.match(
          /bg-\[url\(['"]?([^'"\]]+?)['"]?\)\]/
        )
        if (match) {
          image = match[1]
        }
      }

      return {
        element,
        codeSectionElement: getCodeSectionElement(element),
        imageSrc: image,
      }
    }, [])

    const getCodeSectionElement = (element: HTMLElement) => {
      let codeSectionElement: HTMLElement = element
      while (
        codeSectionElement &&
        codeSectionElement.classList.contains('code-section') === false
      ) {
        codeSectionElement = codeSectionElement.parentElement
      }
      return codeSectionElement
    }

    const handleMouseOver = (event: MouseEvent) => {
      if (!isInEditMode) {
        return
      }
      setHoveredElement(event.target as HTMLElement)
    }

    const handleMouseClick = (event: MouseEvent) => {
      if (!isInEditMode) {
        return
      }
      if (getCodeSectionElement(event.target as HTMLElement)) {
        event.preventDefault()
        setClickedElement(event.target as HTMLElement)
      }
    }

    const removeContentEditable = (element: HTMLElement) => {
      element.removeAttribute('contenteditable')
    }

    const makeElementEditable = (element: HTMLElement) => {
      element.setAttribute('contenteditable', 'true')

      element.addEventListener('keydown', (event: KeyboardEvent) => {
        if (event.key === 'Enter') {
          event.preventDefault()

          const sel = iframeRef.current.contentWindow.getSelection()
          if (!sel.rangeCount) return

          const range = sel.getRangeAt(0)
          const br =
            iframeRef.current.contentWindow.document.createElement('br')
          const textNode =
            iframeRef.current.contentWindow.document.createTextNode('\u200B')

          range.deleteContents()
          range.insertNode(br)
          range.collapse(false)

          // Add text node and place the caret at its end
          range.insertNode(textNode)
          range.setStartAfter(textNode)

          sel.removeAllRanges()
          sel.addRange(range)
        }
      })
    }

    // used by parent to get full page after modifying it
    const getUpdatedPage = () => {
      // Grab all code-section elements from the DOM
      const codeSections = Array.from(
        iframeRef.current.contentWindow.document.querySelectorAll(
          '.code-section'
        )
      )

      // Create an array to store updated code sections
      const updatedCodeSections = []

      for (const codeSection of codeSections) {
        // Since we're manipulating the HTML, we remove classes to avoid issues
        const $ = cheerio.load(codeSection.outerHTML)
        $('.clicked-element').removeClass('clicked-element')
        $('.hovered-element').removeClass('hovered-element')
        $('[contenteditable]').removeAttr('contenteditable')

        // Check for a data-update-id attribute - this is set manually to make the id a vanity name for linking, e.g. #about-us instead of #random123
        const newId = codeSection.getAttribute('data-update-id')
        const currentId = codeSection.id
        if (newId) {
          $('.code-section').attr('id', newId)
          $('.code-section').removeAttr('data-update-id')
        }

        const newHtml = $('body').html()

        // Add the updated section to the array
        updatedCodeSections.push({
          id: currentId,
          newId: newId, // Add this field to the object
          html: newHtml,
        })
      }

      const mappedCodeSections = page.codeSections.map((section) => {
        const updatedSection = updatedCodeSections.find(
          (updated) => updated.id === section.id
        )

        if (updatedSection) {
          const newSection = { ...section, ...updatedSection }
          if (updatedSection.newId) {
            newSection.id = updatedSection.newId // Update the id if there's a newId
          }
          return newSection
        }

        return section
      })

      const updatedPage: PageWithCodeSections = {
        ...page,
        codeSections: mappedCodeSections,
      }

      const updatedHeaderCodeSection = updatedCodeSections.find((section) => {
        return headerCodeSection && section.id === headerCodeSection.id
      })

      if (updatedHeaderCodeSection && updatedHeaderCodeSection.newId) {
        updatedHeaderCodeSection.id = updatedHeaderCodeSection.newId
      }

      const updatedFooterCodeSection = updatedCodeSections.find((section) => {
        return footerCodeSection && section.id === footerCodeSection.id
      })

      if (updatedFooterCodeSection && updatedFooterCodeSection.newId) {
        updatedFooterCodeSection.id = updatedFooterCodeSection.newId
      }

      return {
        updatedPage,
        headerCodeSection: updatedHeaderCodeSection,
        footerCodeSection: updatedFooterCodeSection,
      }
    }

    // notify parent to save the page
    const updatePage = async () => {
      const { updatedPage, headerCodeSection, footerCodeSection } =
        getUpdatedPage()

      onPageChange &&
        onPageChange({
          page: updatedPage,
          headerCodeSection,
          footerCodeSection,
        })
    }

    useEffect(() => {
      // handle hovered element

      if (hoveredElement) {
        const codeSectionElement = getCodeSectionElement(hoveredElement)
        if (!codeSectionElement) {
          return
        }

        if (previousHoveredElement) {
          previousHoveredElement.classList.remove('hovered-element')
        }

        setPreviousHoveredElement(hoveredElement)
        hoveredElement.classList.add('hovered-element')

        onElementHover && onElementHover(getElementContext(hoveredElement))
      }
    }, [hoveredElement])

    useEffect(() => {
      // handle clicked element

      //unclick the previous element
      if (previousClickedElement) {
        previousClickedElement.classList.remove('clicked-element')
        setPreviousClickedElement(null) //will this cause some weirdness with setPreviousClickedElement again in the next if statement?
      }

      if (clickedElement) {
        setPreviousClickedElement(clickedElement)
        clickedElement.classList.add('clicked-element')

        onElementClick && onElementClick(getElementContext(clickedElement))

        const codeSectionElement = getCodeSectionElement(clickedElement)

        if (
          codeSectionElement &&
          codeSectionElement.classList.contains('code-section')
        ) {
          setClickedCodeSection(codeSectionElement)
        }
      }

      if (!clickedElement) {
        setClickedCodeSection(null)
      }
    }, [clickedElement])

    useEffect(() => {
      if (isNewChatPageFrame !== true) {
        return
      }

      if (previousClickedCodeSection) {
        previousClickedCodeSection.classList.remove('clicked-code-section')
        setPreviousClickedCodeSection(null) //will this cause some weirdness with setPreviousClickedElement again in the next if statement?
      }

      if (clickedCodeSection) {
        setPreviousClickedCodeSection(clickedCodeSection)
        clickedCodeSection.classList.add('clicked-code-section')
      }
    }, [clickedCodeSection])

    useEffect(() => {
      // update which element is editable when we click on a new element
      if (
        previousClickedElement &&
        previousClickedElement.getAttribute('contenteditable') === 'true'
      ) {
        removeContentEditable(previousClickedElement)

        // save the text if it changed
        if (
          editableElementText.current &&
          previousClickedElement.textContent !== editableElementText.current
        ) {
          updatePage()
        }
      }

      const hasText = clickedElement && clickedElement.textContent.trim() !== ''

      //if we click a new element that has text, lets make this element editable
      if (hasText) {
        editableElementText.current = clickedElement.textContent
        makeElementEditable(clickedElement)
      }
    }, [clickedElement])

    const addNewCodeSectionAbove = () => {
      onAddNewCodeSectionAbove &&
        onAddNewCodeSectionAbove(clickedCodeSection.id)
    }

    const addNewCodeSectionBelow = () => {
      onAddNewCodeSectionBelow &&
        onAddNewCodeSectionBelow(clickedCodeSection.id)
    }

    const moveClickedCodeSectionUp = () => {
      onMoveClickedCodeSectionUp &&
        onMoveClickedCodeSectionUp(clickedCodeSection.id)
    }

    const moveClickedCodeSectionDown = () => {
      onMoveClickedCodeSectionDown &&
        onMoveClickedCodeSectionDown(clickedCodeSection.id)
    }

    const deleteClickedCodeSection = () => {
      onDeleteClickedCodeSection &&
        onDeleteClickedCodeSection(clickedCodeSection.id)
    }

    if (
      !loading &&
      !isAuthenticated &&
      !page.startedGeneratingAt &&
      onPageFrameLinkClick
    ) {
      return (
        <div className="flex flex-col items-center justify-center p-20">
          <div>You must log in to see this page</div>
          <div>
            <button
              onClick={() => {
                onPageFrameLinkClick('/home')
              }}
              className="mt-2 text-blue-500 underline"
            >
              Go back to your website
            </button>
          </div>
        </div>
      )
    }

    return (
      <div translate="no">
        <Frame
          ref={iframeRef}
          contentDidMount={contentDidMount}
          contentDidUpdate={adjustHeight}
          className={className}
          initialContent={getInitialContent({ cssVariables, fonts })}
        >
          <div className="[font-family:var(--font-family-body)]">
            {headerCodeSection && (
              <div
                id={headerCodeSection.id}
                dangerouslySetInnerHTML={{ __html: headerCodeSection.html }}
              />
            )}
            {page.codeSections &&
              Array.isArray(page.codeSections) &&
              page.codeSections.map((codeSection: CodeSection, index) => (
                <div key={index}>
                  {index > 0 &&
                    clickedCodeSection &&
                    clickedCodeSection.id === codeSection.id && (
                      <div className="relative">
                        <div className="absolute top-[-40px] z-50 h-20 w-full">
                          <div className="flex h-full items-center justify-center space-x-1 sm:space-x-4">
                            {onAddNewCodeSectionAbove && (
                              <button
                                onClick={addNewCodeSectionAbove}
                                className="rounded border border-blue-800 bg-blue-500 px-1 py-2 text-xs text-white sm:px-4 sm:text-base"
                              >
                                <i className="fa-regular fa-plus mr-2"></i>
                                <span>Add Section</span>
                              </button>
                            )}
                            {index > 0 && onMoveClickedCodeSectionUp && (
                              <button
                                onClick={moveClickedCodeSectionUp}
                                className="rounded border border-blue-800 bg-blue-500 px-1 py-2 text-xs text-white sm:px-4 sm:text-base"
                              >
                                <i className="fa-regular fa-arrow-up mr-2"></i>
                                <span>Move Up</span>
                              </button>
                            )}
                            {index < page.codeSections.length - 1 &&
                              onMoveClickedCodeSectionDown && (
                                <button
                                  onClick={moveClickedCodeSectionDown}
                                  className="rounded border border-blue-800 bg-blue-500 px-1 py-2 text-xs text-white sm:px-4 sm:text-base"
                                >
                                  <i className="fa-regular fa-arrow-down mr-2"></i>
                                  <span>Move Down</span>
                                </button>
                              )}
                            {onDeleteClickedCodeSection && (
                              <button
                                onClick={() => {
                                  if (
                                    window.confirm(
                                      'Are you sure you want to delete this section?'
                                    )
                                  ) {
                                    deleteClickedCodeSection()
                                  }
                                }}
                                className="rounded border border-blue-800 bg-blue-500 px-1 py-2 text-xs text-white sm:px-4 sm:text-base"
                              >
                                <i className="fa-regular fa-trash mr-2"></i>
                                <span>Delete Section</span>
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  <div
                    key={index}
                    id={codeSection.id}
                    dangerouslySetInnerHTML={{ __html: codeSection.html }}
                  />
                  {clickedCodeSection &&
                    clickedCodeSection.id === codeSection.id && (
                      <div className="relative">
                        <div className="absolute top-[-40px] z-50 h-20 w-full">
                          <div className="flex h-full items-center justify-center space-x-1 sm:space-x-4">
                            {onAddNewCodeSectionBelow && (
                              <button
                                onClick={addNewCodeSectionBelow}
                                className="rounded border border-blue-800 bg-blue-500 px-1 py-2 text-xs text-white sm:px-4 sm:text-base"
                              >
                                <i className="fa-regular fa-plus mr-2"></i>
                                <span>Add Section</span>
                              </button>
                            )}
                            {index > 0 && onMoveClickedCodeSectionUp && (
                              <button
                                onClick={moveClickedCodeSectionUp}
                                className="rounded border border-blue-800 bg-blue-500 px-1 py-2 text-xs text-white sm:px-4 sm:text-base"
                              >
                                <i className="fa-regular fa-arrow-up mr-2"></i>
                                <span>Move Up</span>
                              </button>
                            )}
                            {index < page.codeSections.length - 1 &&
                              onMoveClickedCodeSectionDown && (
                                <button
                                  onClick={moveClickedCodeSectionDown}
                                  className="rounded border border-blue-800 bg-blue-500 px-1 py-2 text-xs text-white sm:px-4 sm:text-base"
                                >
                                  <i className="fa-regular fa-arrow-down mr-2"></i>
                                  <span>Move Down</span>
                                </button>
                              )}
                            {onDeleteClickedCodeSection && (
                              <button
                                onClick={() => {
                                  if (
                                    window.confirm(
                                      'Are you sure you want to delete this section?'
                                    )
                                  ) {
                                    deleteClickedCodeSection()
                                  }
                                }}
                                className="rounded border border-blue-800 bg-blue-500 px-1 py-2 text-xs text-white sm:px-4 sm:text-base"
                              >
                                <i className="fa-regular fa-trash mr-2"></i>
                                <span>Delete Section</span>
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                </div>
              ))}
            {!page.codeSections && (
              <div className="flex items-center justify-center p-20">
                <i className="fa-regular fa-spinner-third fa-spin mr-2"></i>
                <span>Loading...</span>
              </div>
            )}
            {footerCodeSection && (
              <div
                id={footerCodeSection.id}
                dangerouslySetInnerHTML={{ __html: footerCodeSection.html }}
              />
            )}
          </div>
        </Frame>
      </div>
    )
  }
)

export default PageFrame

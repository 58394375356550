import React, { useState, useEffect } from 'react'

import { useQuery } from '@redwoodjs/web'

const FIND_CHAT_TEMPLATES = gql`
  query FindChatTemplatesQuery {
    chatTemplates {
      id
      templateName
      templateDescription
      smallScreenshotUrl
      fullPageScreenshotUrl
      html
    }
  }
`

const TemplatePickerPanel = ({ onTemplatePicked }) => {
  const [templates, setTemplates] = useState([])
  // const [selectedTemplate, setSelectedTemplate] = useState(null)
  const [previewTemplate, setPreviewTemplate] = useState(null)
  const [isMobile, setIsMobile] = useState(false)
  const [showOptions, setShowOptions] = useState({}) // Object to track if options are shown per template

  const { loading, error, refetch } = useQuery(FIND_CHAT_TEMPLATES, {
    onCompleted(data) {
      setTemplates(data.chatTemplates as any)
    },
  })

  // Detect if the user is on mobile
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768) // Assuming < 768px is mobile
    }

    handleResize() // Set the initial value
    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const handleTemplateClick = (templateId) => {
    if (isMobile) {
      // Toggle visibility of options for this template
      setShowOptions((prevState) => ({
        ...prevState,
        [templateId]: !prevState[templateId],
      }))
    }
  }

  return (
    <div className="h-full overflow-scroll rounded bg-white p-2 shadow-md">
      {loading && (
        <div>
          <i className="fa-regular fa-spinner-third fa-spin"></i>
        </div>
      )}
      {!loading && (
        <div className="my-4 grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
          {templates.map((template, index) => (
            <div
              key={template.id}
              className={`p-2 ${isMobile ? '' : 'group'}`}
              onClick={() => handleTemplateClick(template.id)}
              tabIndex={index}
              role="button"
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleTemplateClick(template.id)
                }
              }}
            >
              <div className="relative mb-4 border border-gray-200 shadow transition-all duration-300 ease-in-out group-hover:shadow-lg">
                <img
                  className={`h-[560px] w-96 transition-opacity duration-300 ease-in-out ${
                    isMobile && showOptions[template.id] ? 'opacity-5' : ''
                  }`}
                  src={template.smallScreenshotUrl}
                  alt={template.templateName}
                />
                <div
                  className={`absolute inset-0 flex flex-row items-end justify-end ${
                    isMobile
                      ? showOptions[template.id]
                        ? 'opacity-100'
                        : 'pointer-events-none opacity-0'
                      : 'opacity-0 group-hover:opacity-100'
                  } transition-opacity duration-300 ease-in-out`}
                >
                  <div className="flex w-full flex-row justify-center border-t border-gray-300 bg-blue-500 text-white">
                    <button
                      className="w-1/2 border-r border-gray-300 py-5 text-base uppercase hover:bg-blue-600"
                      onClick={(e) => {
                        e.stopPropagation()
                        setPreviewTemplate(template)
                      }}
                    >
                      Preview
                    </button>
                    <button
                      className="w-1/2 py-5 text-lg font-bold uppercase hover:bg-blue-600"
                      onClick={(e) => {
                        e.stopPropagation()
                        onTemplatePicked(template.id)
                      }}
                    >
                      Start
                      <i className="fas fa-arrow-right ml-2"></i>
                    </button>
                  </div>
                </div>
              </div>
              <h3 className="text-lg font-semibold">{template.templateName}</h3>
              <p className="text-sm">{template.templateDescription}</p>
            </div>
          ))}
        </div>
      )}
      {!loading && previewTemplate && (
        <div className="absolute left-0 top-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="rounded bg-white p-4 shadow-lg">
            <button
              className="absolute right-4 top-3"
              onClick={() => setPreviewTemplate(null)}
            >
              <i className="fa-regular fa-times-circle fa-2xl"></i>
            </button>
            <h2 className="text-xl font-semibold">
              {previewTemplate.templateName}
            </h2>
            <div className="overflow-scroll border border-0 border-t-2 border-black">
              <img src={previewTemplate.fullPageScreenshotUrl} alt="" />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default TemplatePickerPanel

import { Fragment, useState, useEffect } from 'react'

import { Popover, Transition } from '@headlessui/react'
import { Website } from 'types/graphql'

import { Link } from '@redwoodjs/router'
import { useMutation } from '@redwoodjs/web'

import { useAuth } from 'src/auth'

const GET_STRIPE_CUSTOMER_PORTAL_SESSION_URL = gql`
  mutation GetStripeCustomerPortalSessionUrlMutation {
    getStripeCustomerPortalSessionUrl
  }
`

type NavbarProps = {
  buttons: string[]
  website: Website
  navigateToPanel?: (panelName: string) => void
  onPublishClicked?: () => Promise<void>
}
const Navbar = ({
  buttons,
  website,
  navigateToPanel,
  onPublishClicked,
}: NavbarProps) => {
  const { loading, isAuthenticated, logIn, signUp, logOut } = useAuth()

  const [loadingBillingPortal, setLoadingBillingPortal] = useState(false)
  const [hasUnpublishedChanges, setHasUnpublishedChanges] = useState(false)
  const [isPublishing, setIsPublishing] = useState(false)

  const [getStripeCustomerPortalSessionUrl] = useMutation(
    GET_STRIPE_CUSTOMER_PORTAL_SESSION_URL
  )

  useEffect(() => {
    const _hasUnpublishedChanges =
      website?.lastUpdated &&
      new Date(website.lastUpdated) > new Date(website.proPublishedAt)
    setHasUnpublishedChanges(_hasUnpublishedChanges)
  }, [website])

  useEffect(() => {
    if (isPublishing) {
      onPublishClicked().then(() => {
        setIsPublishing(false)
      })
    }
  }, [isPublishing])

  const onClickLogin = (event) => {
    event.preventDefault()
    logIn({
      appState: {
        targetUrl: `/login-success?redirectTo=${encodeURIComponent(
          window.location.pathname + window.location.search
        )}`,
      },
    })
  }

  const onClickSignup = (event) => {
    event.preventDefault()
    signUp({
      appState: {
        targetUrl: `/login-success?redirectTo=${encodeURIComponent(
          window.location.pathname + window.location.search
        )}`,
      },
    })
  }
  const navigateToBillingPortal = async () => {
    setLoadingBillingPortal(true)
    const res = await getStripeCustomerPortalSessionUrl()

    console.log(res)

    window.location.href = res.data.getStripeCustomerPortalSessionUrl
  }

  return (
    <div className="shadow-s flex flex-row items-center justify-between bg-white p-1">
      <div className="flex">
        <Link to="/" className="ml-2 flex items-center">
          <img
            src="/logo_full_black.png"
            alt="Logo"
            className="mr-2 h-5 w-auto"
          />
          <span className="text-xl font-semibold tracking-tight text-gray-800">
            LandingSite
          </span>
        </Link>
      </div>

      <div className="flex">
        {isAuthenticated && buttons.includes('publish') && (
          <button
            className="mr-2 rounded border p-1 px-2"
            onClick={() => {
              setIsPublishing(true)
            }}
          >
            {isPublishing && (
              <>
                <i className="fa-regular fa-sparkles mr-2 text-yellow-400"></i>
                Launching...
              </>
            )}
            {!isPublishing && website && !website.proPublishedAt && (
              <>
                <i className="fa-regular fa-sparkles mr-2 text-yellow-400"></i>
                Launch website
              </>
            )}
            {!isPublishing &&
              website &&
              website.proPublishedAt &&
              hasUnpublishedChanges && (
                <>
                  <i className="fa-regular fa-sparkles mr-2 text-yellow-400"></i>
                  Update website
                </>
              )}
            {!isPublishing &&
              website &&
              website.proPublishedAt &&
              !hasUnpublishedChanges && (
                <>
                  <i className="fa-regular fa-sparkles mr-2"></i>
                  Up to date
                </>
              )}
          </button>
        )}
        {isAuthenticated && buttons.includes('settings') && (
          <div className="group relative flex items-center">
            <button
              className="h-8 w-8 justify-center rounded bg-white p-1"
              onClick={() => navigateToPanel('settings')}
            >
              <i className="fa-regular fa-gear"></i>
            </button>
            <span className="pointer-events-none absolute bottom-[-30px] left-1/2 z-10 -translate-x-1/2 transform rounded bg-gray-200 px-2 py-1 text-xs opacity-0 transition-opacity group-hover:opacity-100">
              Settings
            </span>
          </div>
        )}
        {isAuthenticated && buttons.includes('user') && (
          <Popover className="relative flex">
            {({ open }) => (
              <>
                <Popover.Button
                  className={`
                ${open ? '' : 'text-opacity-90'}
                group inline-flex h-[32px] w-[32px] flex-grow items-center justify-center rounded-lg p-2 text-base font-medium text-gray-800 hover:border focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
                >
                  <i className="fa-solid fa-ellipsis-vertical"></i>
                </Popover.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1"
                >
                  <Popover.Panel className="absolute -left-3/4 z-[80] mt-10 w-[180px] -translate-x-24 transform px-4 sm:w-[140px] sm:px-0">
                    <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                      <a
                        href="/"
                        className="flex w-full items-center justify-between bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-200"
                      >
                        <span>Dashboard</span>
                        <i className="fa-solid fa-user"></i>
                      </a>
                      <button
                        className="flex w-full items-center justify-between bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-200"
                        onClick={navigateToBillingPortal}
                      >
                        <span>Billing</span>
                        {!loadingBillingPortal && (
                          <i className="fa-regular fa-credit-card"></i>
                        )}
                        {loadingBillingPortal && (
                          <i className="fa-regular fa-spinner-third animate-spin"></i>
                        )}
                      </button>
                      <button
                        className="flex w-full items-center justify-between bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-200"
                        onClick={() => {
                          logOut({
                            logoutParams: {
                              returnTo: 'https://www.landingsite.ai/',
                            },
                          })
                        }}
                      >
                        <span>Log Out</span>
                        <i className="fa-solid fa-sign-out"></i>
                      </button>
                    </div>
                  </Popover.Panel>
                </Transition>
              </>
            )}
          </Popover>
        )}
      </div>
    </div>
  )
}

export default Navbar

import {
  useEffect,
  useRef,
  useState,
  useImperativeHandle,
  forwardRef,
} from 'react'

import {
  ArrowRight,
  X,
  ArrowCounterClockwise,
  CircleNotch,
  CrosshairSimple,
} from '@phosphor-icons/react'
import clsx from 'clsx'
import { AssistantStream } from 'openai/lib/AssistantStream'
import {
  ImageFile,
  Message,
  Text,
  TextDelta,
} from 'openai/resources/beta/threads/messages'
import {
  RequiredActionFunctionToolCall,
  Run,
} from 'openai/resources/beta/threads/runs/runs'
import {
  ToolCall,
  ToolCallDelta,
} from 'openai/resources/beta/threads/runs/steps'
import { useRemark } from 'react-remark'

import { useMutation } from '@redwoodjs/web'

import { useAuth } from 'src/auth'
import { Media } from 'src/lib/media'

import { FrameElementEvent } from '../PageFrame/PageFrame'

const CREATE_UPLOADED_FILE = gql`
  mutation CreateUploadedFileMutation($input: CreateUploadedFileInput!) {
    createUploadedFile(input: $input) {
      __typename
      id
      fileName
      size
      uploadUrl
      s3Url
    }
  }
`

export type ChatPanelHandler = {
  sendAssistantMessage: (message: string) => void
  sendUserMessage: (message: string) => void
  showComponent: (componentName: string) => void
}

type TChatMessage = {
  text: string
  role: string
  elementLabel?: string
  image?: string
}

type ChatPanelProps = {
  websiteId: string
  clickedElement?: FrameElementEvent
  functions: Record<string, (args?: any) => any>
  handleClearClickedElement: () => void
  disableChatSubmit?: boolean
  onMediaUploaded?: (media: Media) => void
  isOnboarding?: boolean
  gettingStartedState?: 'edit' | 'publish' | 'setupDomain' | 'done'
  onSetUpYourDomainButtonClicked?: () => void
}
const ChatPanel = forwardRef(
  (
    {
      websiteId,
      functions,
      clickedElement,
      handleClearClickedElement,
      disableChatSubmit,
      onMediaUploaded,
      isOnboarding,
      gettingStartedState,
      onSetUpYourDomainButtonClicked,
    }: ChatPanelProps,
    ref
  ) => {
    useImperativeHandle<unknown, ChatPanelHandler>(
      ref,
      () => ({
        sendAssistantMessage: async (message) => {
          await loadPromiseRef.current

          addMessage({ role: 'assistant', text: message })

          //isOnboarding can be null when we are still loading
          if (isOnboarding !== false) {
            return
          }

          sendAssistantMessage(message)
        },
        sendUserMessage: async (message) => {
          await loadPromiseRef.current

          handleSubmit(message)
        },
        showComponent: async (componentName) => {
          await loadPromiseRef.current

          //will abstract this later. for now, just show login/signup
          if (componentName === 'loginComponent') {
            addMessage({
              role: 'system',
              text: '<loginComponent>',
            })
          }
        },
      }),
      [isOnboarding]
    )

    const [messages, setMessages] = useState<TChatMessage[]>([])
    const [loading, setLoading] = useState(true)
    const [userInput, setUserInput] = useState('')
    const [submitDisabled, setSubmitDisabled] = useState(
      disableChatSubmit || false
    )
    const { getToken } = useAuth()
    const [waiting, setWaiting] = useState(false)
    const [lastOnboardingState, setLastOnboardingState] = useState<boolean>()

    const loadPromiseRef = useRef<Promise<void> | null>(null)

    const fileInputRef = useRef<HTMLInputElement>(null)
    const [createUploadedFile] = useMutation(CREATE_UPLOADED_FILE)

    useEffect(() => {
      const getMessages = async () => {
        //dont let someone add messages while loading
        loadPromiseRef.current = (async () => {
          setSubmitDisabled(true)
          const response = await fetch(
            `${process.env.CLOUDFLARE_WORKER_ASSISTANT_API}/api/website/${websiteId}/messages`,
            {
              headers: {
                Authorization: `Bearer ${await getToken()}`,
              },
            }
          )

          const data = await response.json()
          let messages = data.messages || []

          messages = messages.map((message) => ({
            text: message.content[0].text.value,
            role: message.role,
            elementLabel: message.metadata.element_label,
            image: message.metadata.image,
          }))

          //if we go from onboarding to chat, we need to add the onboarding messages
          setMessages((prevMessages) => {
            if (lastOnboardingState === true && isOnboarding === false) {
              return [...prevMessages, ...messages]
            }

            return messages
          })

          // if there's an ongoing run, restart it
          if (data.run) {
            handleRequiresAction(data.run)
          }

          setSubmitDisabled(disableChatSubmit || false)
          setLoading(false)
        })()
      }

      if (isOnboarding) {
        setLoading(false)
      } else if (isOnboarding === false) {
        setLoading(true)
        getMessages()
      }

      setLastOnboardingState(isOnboarding)
    }, [isOnboarding])

    // automatically scroll to bottom of chat
    const messagesEndRef = useRef<HTMLDivElement | null>(null)
    const scrollToBottom = () => {
      messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' })
    }
    useEffect(() => {
      scrollToBottom()
    }, [messages])

    const sendAssistantMessage = async (text: string) => {
      await fetch(
        `${process.env.CLOUDFLARE_WORKER_ASSISTANT_API}/api/website/${websiteId}/messages`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${await getToken()}`,
          },
          body: JSON.stringify({
            role: 'assistant',
            text,
          }),
        }
      )
    }

    const sendUserMessage = async (text: string) => {
      const response = await fetch(
        `${process.env.CLOUDFLARE_WORKER_ASSISTANT_API}/api/website/${websiteId}/messages`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${await getToken()}`,
          },
          body: JSON.stringify({
            text,
            ...(clickedElement
              ? {
                  clickedCodeSectionHtml:
                    clickedElement.codeSectionElement.outerHTML,
                  clickedElementHtml: clickedElement.element.outerHTML,
                  elementLabel: truncate(
                    getElementLabel(clickedElement?.element),
                    512
                  ),
                  image: clickedElement?.imageSrc,
                }
              : {}),
          }),
        }
      )

      const stream = AssistantStream.fromReadableStream(response.body)
      handleReadableStream(stream)
    }

    const submitActionResult = async (
      runId: string,
      toolCallOutputs: ToolCallOutput[]
    ) => {
      const response = await fetch(
        `${process.env.CLOUDFLARE_WORKER_ASSISTANT_API}/api/website/${websiteId}/actions`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${await getToken()}`,
          },
          body: JSON.stringify({
            runId: runId,
            toolCallOutputs: toolCallOutputs,
          }),
        }
      )

      const stream = AssistantStream.fromReadableStream(response.body)
      handleReadableStream(stream)
    }

    const resetChat = async () => {
      await fetch(
        `${process.env.CLOUDFLARE_WORKER_ASSISTANT_API}/api/website/${websiteId}/messages`,
        {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${await getToken()}`,
          },
        }
      )
      setMessages([])
    }
    const cancelRun = async () => {
      console.log('cancelRun')
      await fetch(
        `${process.env.CLOUDFLARE_WORKER_ASSISTANT_API}/api/website/${websiteId}/actions`,
        {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${await getToken()}`,
          },
        }
      )
      handleRunCompleted()
    }

    const handleSubmit = (messageOverride?: string) => {
      if (loading) {
        return
      }

      const input = messageOverride || userInput
      if (!input.trim()) return
      sendUserMessage(input)
      addMessage({
        role: 'user',
        text: input,
        elementLabel:
          clickedElement && getElementLabel(clickedElement?.element),
        image: clickedElement?.imageSrc,
      })
      setUserInput('')
      setSubmitDisabled(true)
      scrollToBottom()
      setWaiting(true)
    }

    const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (e.key === 'Enter' && !e.shiftKey) {
        e.preventDefault()
        if (submitDisabled) {
          return
        }

        handleSubmit()
      }
    }

    // textCreated - create new assistant message
    const handleTextCreated = (content: Text) => {
      console.log('handleTextCreated', content)
      // add an empty message to start appending to
      addMessage({
        role: 'assistant',
        text: '',
      })
    }

    // textDelta - append text to last assistant message
    const handleTextDelta = (delta: TextDelta, snapshot: Text) => {
      console.log(new Date().getTime(), 'handleTextDelta', delta, snapshot)
      if (delta.value != null) {
        appendToLastMessage(delta.value)
      } else if (snapshot.value != null) {
        replaceLastMessage(snapshot.value)
      }
    }

    // imageFileDone - show image in chat
    // TODO we don't have upload yet
    const handleImageFileDone = (image: ImageFile, snapshot: Message) => {
      console.log('handleImageFileDone', image, snapshot)
      appendToLastMessage(
        `\n![${image.file_id}](/api/files/${image.file_id})\n`
      )
    }

    // toolCallCreated - log new tool call
    const toolCallCreated = (toolCall: ToolCall) => {
      console.log('toolCallCreated', toolCall)
      // nothing to do
    }

    // toolCallDelta - log delta and snapshot for the tool call
    const toolCallDelta = (delta: ToolCallDelta, snapshot: ToolCall) => {
      // we don't need this, not rendering inline code interpreter responses
      console.log('toolCallDelta', delta, snapshot)
      if (delta.type != 'code_interpreter') return
      if (!delta.code_interpreter.input) return
      appendToLastMessage(delta.code_interpreter.input)
    }

    const functionCallHandler = async (
      toolCall: RequiredActionFunctionToolCall
    ) => {
      console.log('functionCallHandler', toolCall)
      return functions[toolCall.function.name](toolCall.function.arguments)
    }

    // handleRequiresAction - handle function call
    type ToolCallOutput = { output: string; tool_call_id: string }
    const handleRequiresAction = async (run: Run) => {
      console.log('handleRequiresAction', event)
      const thisRunId = run.id
      setWaiting(true)
      const toolCalls = run.required_action.submit_tool_outputs.tool_calls
      // loop over tool calls and call function handler
      const toolCallOutputs = await Promise.all(
        toolCalls.map(async (toolCall) => {
          const result = await functionCallHandler(toolCall)
          return { output: JSON.stringify(result), tool_call_id: toolCall.id }
        })
      )
      setSubmitDisabled(true)
      submitActionResult(thisRunId, toolCallOutputs)
    }

    // handleRunCompleted - re-enable the input form
    const handleRunCompleted = () => {
      setSubmitDisabled(false)
      setWaiting(false)
    }

    const handleReadableStream = (stream: AssistantStream) => {
      // messages
      stream.on('textCreated', handleTextCreated)
      stream.on('textDelta', handleTextDelta)

      // image
      stream.on('imageFileDone', handleImageFileDone)

      // code interpreter
      stream.on('toolCallCreated', toolCallCreated)
      stream.on('toolCallDelta', toolCallDelta)

      // events without helpers yet (e.g. requires_action and run.done)
      stream.on('event', (event) => {
        if (event.event === 'thread.run.requires_action') {
          handleRequiresAction(event.data)
        }
        if (event.event === 'thread.run.completed') {
          handleRunCompleted()
        }
      })
    }

    const appendToLastMessage = (text: string) => {
      console.log('appendToLastMessage', text)
      setMessages((prevMessages) => {
        const lastMessage = prevMessages[prevMessages.length - 1]
        const updatedLastMessage = {
          ...lastMessage,
          text: lastMessage.text + text,
        }
        return [...prevMessages.slice(0, -1), updatedLastMessage]
      })
    }

    const replaceLastMessage = (text: string) => {
      setMessages((prevMessages) => {
        const lastMessage = prevMessages[prevMessages.length - 1]
        const updatedLastMessage = {
          ...lastMessage,
          text: text,
        }
        return [...prevMessages.slice(0, -1), updatedLastMessage]
      })
    }

    const addMessage = ({ role, text, elementLabel, image }: TChatMessage) => {
      console.log('adding message', role, text)
      setMessages((prevMessages) => [
        ...prevMessages,
        { role, text, elementLabel, image },
      ])
    }

    const handleGalleryClick = () => {
      console.log('handleGalleryClick')
      let galleryType = 'images'
      if (clickedElement && clickedElement.element) {
        galleryType =
          clickedElement.element.getAttribute(
            'data-landingsite-gallery-type'
          ) || 'images'
      }
      functions['openImageGallery'](galleryType)
    }

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files[0]
      console.log('handleFileChange', file)

      handleFileSelect(file)
    }

    const handleFileSelect = async (file: File) => {
      if (!file) {
        return
      }

      try {
        const uploadedFileResponse = await createUploadedFile({
          variables: {
            input: {
              fileName: file.name,
              size: file.size,
            },
          },
        })

        const uploadedFile = uploadedFileResponse.data.createUploadedFile

        const uploadResponse = await fetch(uploadedFile.uploadUrl, {
          method: 'PUT',
          body: file,
        })

        if (!uploadResponse.ok) {
          console.error(uploadResponse)
        }

        const newId = 'i' + Math.random().toString(36).substring(7) //shouldnt start with a number
        const media = {
          id: newId,
          src: 'upload',
          type: 'image',
          dataMedia: {},
          urls: { display: uploadedFile.s3Url },
        }

        onMediaUploaded(media as Media)
      } catch (e) {
        console.log('error uploading media', e)
      }
    }

    const setUpYourDomainButtonClicked = () => {
      onSetUpYourDomainButtonClicked && onSetUpYourDomainButtonClicked()
    }

    return (
      <>
        <input
          ref={fileInputRef}
          type="file"
          accept="image/*"
          className="hidden"
          onChange={handleFileChange}
        />
        {!loading && gettingStartedState && gettingStartedState !== 'done' && (
          <div className="mt-2 px-4 py-1">
            <div className="rounded-md border border-gray-300">
              {/* <div className="space-y-1 p-4 text-center">
                <p className="text-xs uppercase text-gray-400">
                  Getting Started
                </p>
                <h2 className="text-lg font-medium text-gray-900">
                  Let's launch your new website!
                </h2>
                <p className="text-sm text-gray-500">
                  When you're ready, click this button to continue.
                </p>
                <p className="text-sm text-gray-500">
                  You can keep editing after you launch.
                </p>
                <div className="inline-flex flex-col space-y-2">
                  <button
                    onClick={setUpYourDomainButtonClicked}
                    className="mt-2 rounded-md border border-gray-300 bg-white px-4 py-2 text-gray-700 hover:bg-blue-600 hover:text-white"
                  >
                    Set Up Your Domain
                  </button>
                  <button
                    onClick={() => {}}
                    className="text-sm text-gray-500 underline"
                  >
                    Not Now
                  </button>
                </div>
              </div> */}
              {!loading && gettingStartedState === 'edit' && (
                <div className="space-y-1 p-4 text-center">
                  <p className="text-xs uppercase text-gray-400">
                    Getting Started
                  </p>
                  <h2 className="text-xl font-medium text-gray-900">
                    Edit your website
                  </h2>
                  <p className="text-sm text-gray-500">
                    Ask the AI to make changes to your website.
                  </p>
                  <div className="inline-flex flex-col space-y-2">
                    <button
                      onClick={setUpYourDomainButtonClicked}
                      className="text-sm text-gray-400 underline"
                    >
                      <span>Click Here To Set Up Your Domain</span>
                      <i className="fas fa-arrow-right ml-1 text-xs"></i>
                    </button>
                  </div>
                </div>
              )}
              {!loading && gettingStartedState === 'setupDomain' && (
                <div className="space-y-1 p-4 text-center">
                  <p className="text-xs uppercase text-gray-400">
                    Getting Started
                  </p>
                  <h2 className="text-lg font-medium text-gray-900">
                    Link your domain
                  </h2>
                  <p className="text-sm text-gray-500">
                    Your domain hasn't been verified yet.
                  </p>
                  <div className="inline-flex flex-col space-y-2">
                    <button
                      onClick={setUpYourDomainButtonClicked}
                      className="mt-2 text-sm text-gray-400 underline"
                    >
                      See Domain Verification Instructions
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
        <div className="my-2 flex-grow overflow-y-auto">
          {loading && (
            <div className="flex flex-col gap-4 px-4 py-1">
              <ChatMessage waiting={true} customRole={'assistant'} />
            </div>
          )}
          {!loading && !isOnboarding && messages.length === 0 && (
            <div className="flex flex-col gap-4 px-4 py-1">
              <ChatMessage
                customRole={'assistant'}
                text="Hey there! I'm your web designer. Ask me to change your website or fix any issues!"
              />
            </div>
          )}
          {messages.map((message, index) => (
            <div className="flex flex-col gap-4 px-4 py-1" key={index}>
              <ChatMessage
                customRole={message.role}
                text={message.text}
                elementLabel={message.elementLabel}
                image={message.image}
              />
            </div>
          ))}
          {waiting && (
            <div className="flex flex-col gap-4 px-2 py-1">
              <ChatMessage
                customRole="assistant"
                waiting={true}
                onCancelRun={cancelRun}
              />
            </div>
          )}
          {messages.length !== 0 && !isOnboarding && (
            <button
              className="mb-10 ml-4 flex flex-row text-sm text-gray-500"
              onClick={resetChat}
            >
              <ArrowCounterClockwise className="block h-5 w-5 pr-2" />
              Restart Chat
            </button>
          )}
          <div ref={messagesEndRef} />
        </div>

        {false && clickedElement && (
          <>
            <hr />
            <div className="relative m-2 flex items-start rounded-xl border bg-gray-50 p-2">
              <div className="me-4">
                <span className="flex items-center gap-2 pb-2 text-sm font-medium text-gray-900 ">
                  {!clickedElement.imageSrc &&
                    truncate(getElementLabel(clickedElement.element))}
                  {clickedElement.imageSrc && (
                    <img
                      src={clickedElement.imageSrc}
                      alt="selected element"
                      className="h-20"
                    />
                  )}
                </span>
                <button
                  className="absolute right-2 top-2 text-black"
                  onClick={handleClearClickedElement}
                >
                  <X className="h-3 w-3" />
                </button>
                <span className="flex gap-1 text-xs font-normal text-gray-500">
                  <CrosshairSimple className="inline-block h-4 w-4" />
                  selected element
                </span>
              </div>
            </div>
          </>
        )}

        <div className="focus-within:ring-pacific mx-2 rounded-xl shadow-sm focus-within:shadow-sm focus-within:ring-2">
          <div
            className={clsx({
              'relative rounded-t-xl px-2 py-1': true,
              'rounded-xl': !clickedElement,
              border: !clickedElement,
              'border-x border-t': clickedElement,
              'bg-gray-200': submitDisabled,
              hidden: isOnboarding,
            })}
          >
            <textarea
              className="block min-h-full w-full resize-none scroll-p-5 overflow-x-auto rounded-t-xl px-2 py-2 leading-6 transition duration-100 placeholder:text-neutral-400 focus:outline-none disabled:bg-gray-200"
              placeholder={
                submitDisabled ? '' : 'What would you like to change...'
              }
              disabled={submitDisabled}
              rows={1}
              value={userInput}
              onChange={(e) => setUserInput(e.target.value)}
              onKeyDown={handleKeyDown}
            ></textarea>
            <button
              className="absolute right-2.5 top-1/2 m-0 h-4 w-4 -translate-y-1/2 disabled:opacity-50"
              disabled={submitDisabled}
            >
              <ArrowRight className="block h-4 w-4 align-middle" />
            </button>
            {clickedElement && clickedElement.imageSrc && (
              <>
                <button
                  className="m-1 rounded border border-gray-200 bg-white px-4 py-2 text-sm first:ml-0 last:mr-0 hover:bg-gray-100"
                  onClick={handleGalleryClick}
                >
                  Gallery
                </button>
                <button
                  className="m-1 rounded border border-gray-200 bg-white px-4 py-2 text-sm first:ml-0 last:mr-0 hover:bg-gray-100"
                  onClick={() => fileInputRef.current.click()}
                >
                  Upload
                </button>
                <button className="m-1 rounded border border-gray-200 bg-white px-4 py-2 text-sm first:ml-0 last:mr-0 hover:bg-gray-100">
                  Swap
                </button>
              </>
            )}
          </div>
          {clickedElement && (
            <div className="flex items-center justify-between rounded-b-xl border border-emerald-200 bg-emerald-50 px-4 py-3">
              <div className="flex w-full items-center gap-3">
                <div className="relative mr-20 flex items-start rounded-xl border bg-gray-50 p-2">
                  <div className="me-4">
                    <span className="flex items-center gap-2 pb-2 text-sm font-medium text-gray-900 ">
                      {!clickedElement.imageSrc &&
                        truncate(getElementLabel(clickedElement.element))}
                      {clickedElement.imageSrc && (
                        <img
                          src={clickedElement.imageSrc}
                          alt="selected element"
                          className="h-20"
                        />
                      )}
                    </span>
                    <button
                      className="absolute right-2 top-2 text-black"
                      onClick={handleClearClickedElement}
                    >
                      <X className="h-3 w-3" />
                    </button>
                    <span className="flex gap-1 text-xs font-normal text-gray-500">
                      <CrosshairSimple className="inline-block h-4 w-4" />
                      selected element
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </>
    )
  }
)

const ChatMessage = ({
  customRole,
  text,
  elementLabel,
  image,
  waiting,
  onCancelRun,
}: {
  customRole: string
  text?: string
  elementLabel?: string
  image?: string
  waiting?: boolean
  onCancelRun?: () => void
}) => {
  const [markdown, setMarkdown] = useRemark()

  const { logIn, signUp } = useAuth()

  useEffect(() => {
    setMarkdown(text)
  }, [text])

  const onLoginClick = () => {
    logIn({
      appState: {
        targetUrl: `/login-success?redirectTo=${encodeURIComponent(
          window.location.pathname + window.location.search
        )}`,
      },
    })
  }

  const onSignupClick = () => {
    signUp({
      appState: {
        targetUrl: `/login-success?redirectTo=${encodeURIComponent(
          window.location.pathname + window.location.search
        )}`,
      },
    })
  }

  if (waiting) {
    return (
      <div className="leading-1.5 prose group relative mr-5 flex flex-col whitespace-pre-wrap rounded-e-xl rounded-es-xl border-gray-200 bg-gray-100 p-4 text-sm text-gray-900">
        <div className="justify-left flex items-center space-x-2">
          <div className="h-2 w-2 animate-bounce rounded-full bg-gray-500 [animation-delay:-0.3s]"></div>
          <div className="h-2 w-2 animate-bounce rounded-full bg-gray-500 [animation-delay:-0.15s]"></div>
          <div className="h-2 w-2 animate-bounce rounded-full bg-gray-500"></div>
        </div>
        {onCancelRun && (
          <button
            className="absolute right-2 top-1/2 -translate-y-1/2 rounded-full bg-gray-500 text-white opacity-0 transition-opacity duration-200 hover:bg-gray-900 group-hover:opacity-100"
            onClick={onCancelRun}
            title="Stop"
          >
            <div className="flex h-5 w-5 items-center justify-center">
              <div className="h-2 w-2 rounded-sm bg-white"></div>
            </div>
          </button>
        )}
      </div>
    )
  }

  if (customRole === 'user') {
    return (
      <div className="leading-1.5 prose ml-5 flex flex-col whitespace-pre-wrap rounded-s-xl rounded-ee-xl border-gray-200 bg-blue-100 px-4 py-2.5 text-sm text-gray-900">
        {markdown}
        {(elementLabel || image) && (
          <div className="my-2.5 flex items-start rounded-xl bg-gray-50 p-2">
            <div className="me-2">
              <span className="flex items-center gap-2 pb-2 text-sm font-medium text-gray-900 ">
                {elementLabel && !image && truncate(elementLabel)}
                {image && (
                  <img src={image} alt="selected element" className="h-20" />
                )}
              </span>
              <span className="flex gap-1 text-xs font-normal text-gray-500">
                <CrosshairSimple className="inline-block h-4 w-4" />
                selected element
              </span>
            </div>
          </div>
        )}
      </div>
    )
  }

  if (customRole === 'assistant') {
    return (
      <div className="leading-1.5 prose mr-5 flex flex-col whitespace-pre-wrap rounded-e-xl rounded-es-xl border-gray-200 bg-gray-100 px-4 py-2.5 text-sm text-gray-900">
        <div className="flex items-start">
          {waiting && (
            <button className="mr-2 flex-shrink-0" onClick={onCancelRun}>
              <CircleNotch className="h-4 w-4 animate-spin" />
            </button>
          )}
          <div>{markdown}</div>
        </div>
      </div>
    )
  }

  if (customRole === 'system') {
    if (text === '<loginComponent>') {
      return (
        <div className="leading-1.5 flex flex-col rounded-e-xl rounded-es-xl border-gray-200 bg-gray-100 px-4 py-2.5 text-sm text-gray-900">
          <div className="flex space-x-1">
            <button
              onClick={onLoginClick}
              className="flex-1 rounded bg-white px-2 py-1 text-sm text-gray-800"
            >
              Login
            </button>
            <button
              onClick={onSignupClick}
              className="flex-1 rounded bg-blue-500 px-2 py-1 text-sm text-white"
            >
              Sign Up
            </button>
          </div>
        </div>
      )
    }
  }
}

export default ChatPanel

// overriding truncate from 'src/lib/formatters' to make it shorter
const truncate = (value: string, max = 75) => {
  if (max < 3) {
    max = 3
  }

  let output = value?.toString() ?? ''

  if (output.length > max) {
    output = output.substring(0, max - 3) + '...'
  }

  return output
}

const getElementLabel = (element: HTMLElement) => {
  return element?.innerText || element?.tagName
}
